import { FC, useEffect, useState } from "react";

import { Button, Form, Input, message } from "antd";
import { NavLink, useLocation } from "react-router-dom";

import { RouteLinks } from "services/router.service";
import { Loading } from "components/common";
import api from "services/api.service";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { formatPhoneNumber } from "services/data.service";

import "./RegistrationPage.scss";

const RegistrationPage: FC = () => {
  const loc = useLocation();
  const [ form ] = Form.useForm();
  const { isAuthorized, isMobile } = useAppSelector(getUserSelector);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ submitted, setSubmitted ] = useState(false);
  const [ error, setError ] = useState("");
  const [ token, setToken ] = useState("");

  const handleSubmit = async (values: {
    first_name: string,
    last_name: string,
    email: string,
    phone_number: string,
    password: string,
    confirm_password: string,
  }) => {
    try {
      setIsLoading(true);
      await api.invitationComplete({ ...values, token });

      setIsLoading(false);
      setSubmitted(true);
      setError("");
    } catch (e: any) {
      setError(e.response.data.error);
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const onLoad = async (tokenParam: string) => {
    try {
      await api.invitationCheck(tokenParam);

    } catch (e: any) {
      setError(e.response.data.detail || "Invalid link");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const search = loc.search;
    const params = new URLSearchParams(search);
    const tokenParam = params.get("token") || "";

    setToken(tokenParam);

    if (!tokenParam) {
      setError("Invalid link");
    } else {
      onLoad(tokenParam);
    }
  }, [ loc.search ]);

  return (
    <div className={ `registration-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <Form
        className="registration-form-wrapper"
        layout="vertical"
        onFinish={ handleSubmit }
        form={ form }
      >
        <h3>Sign Up</h3>
        { error !== "" && ( <div className="login-error">{ error }</div> ) }
        { submitted ? (
          <p className="submit-notification">You have been successfully signed up. You will receive a letter when admin accept your profile.</p>
          ) : (
          <>
            <Form.Item className="mg-btm-0">
              <Form.Item
                label="First Name"
                name="first_name"
                rules={ [ { required: true, message: "Please input your first name!" } ] }
                style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={ [ { required: true, message: "Please input your last name!" } ] }
                style={ { width: "calc(50% - 10px)", display: "inline-block" } }
              >
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={ [
                { required: true, message: "Please input user email!" },
                { type: "email", message: "Please input correct email!" },
              ] }
            >
              <Input
                onChange={ (e) => form.setFieldsValue({ phone_number: formatPhoneNumber(e.target.value) }) }
              />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone_number"
              rules={ [ { required: true, message: "Please input your phone number!" } ] }
            >
              <Input
                onChange={ (e) => form.setFieldsValue({ phone_number: formatPhoneNumber(e.target.value) }) }
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={ [ { required: true, message: "Please input password!" } ] }
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={ [ "password" ] }
              hasFeedback
              rules={ [
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The two passwords that you entered do not match!"));
                  },
                }),
              ] }
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="mg-btm-0">
              <Button
                className="big-btn"
                disabled={ !token }
                type="primary"
                htmlType="submit"
              >
                <Loading
                  isLoading={ isLoading }
                  height={ 23 }
                  width={ 55 }
                >
                  Sign up
                </Loading>
              </Button>
            </Form.Item>
          </>
        ) }
      </Form>

      {!isAuthorized && (
        <p className="bottom-description">
          Already have an account? Log in{" "}
          <NavLink to={ RouteLinks.AUTH }>here</NavLink> or navigate the{" "}
          <NavLink to={ RouteLinks.MAIN }>main page</NavLink>.
        </p>
      )}
    </div>
  )
}

export default RegistrationPage;
