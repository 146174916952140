import { FC } from "react";

import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { RouteLinks } from "services/router.service";
import { Loading } from "components/common";
import { useAppSelector } from "store";
import { getContractorsSelector } from "store/contractors/contractors.selector";
import { IContractor } from "store/contractors/contractors.types";
import { formatPhoneNumber } from "services/data.service";
import verified from "data/icons/verified.svg";
import hydraAccount from "data/icons/hydra-account.svg";

import "./ContractorsTable.scss";

const ContractorsTable: FC = () => {
  const navigate = useNavigate();
  const { contractors, isContractorsLoading } = useAppSelector(getContractorsSelector);

  const columns: ColumnsType<IContractor> = [
    {
      title: "Official name",
      dataIndex: "official_name",
      key: "official_name",
      onHeaderCell: () => ({
        id: "products-header-cell",
      }),
      width: 200,
      render: (value) => <p className="active">{value}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      onHeaderCell: () => ({
        id: "products-header-cell",
      }),
      width: 200,
      render: (value, item) => (
        <div style={ { display: "flex", gap: 5 } }>
          <p className="active">{value}</p>

          {item.has_account && <img src={ hydraAccount } alt=""/>}

          {item.has_verified_account && <img src={ verified } alt=""/>}
        </div>
      ),
    },
    {
      title: "Default email",
      dataIndex: "default_email",
      key: "default_email",
      onHeaderCell: () => ({
        id: "products-header-cell",
      }),
      width: 200,
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
      onHeaderCell: () => ({
        id: "products-header-cell",
      }),
      width: 200,
      render: (value) => value && formatPhoneNumber(value),
    },
  ]

  return (
    <Loading isLoading={ isContractorsLoading } isColored isFullWidth height={ 200 }>
      <Table
        className="cases-table products-table"
        id="products-table"
        columns={ columns }
        dataSource={ contractors }
        pagination={ false }
        rowKey={ (item) => item.id }
        scroll={ { y: window.innerHeight - 233 } }
        tableLayout="auto"
        onRow={ (record) => ({
          onClick: () => navigate(`${ RouteLinks.PROTECTED.CONTRACTORS.LIST }/${ record.id }`),
        }) }
      />
    </Loading>
  )
}

export default ContractorsTable;
