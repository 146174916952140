import { useEffect, useState } from "react";

import { Button, Input, Modal } from "antd";

import { arrayMove } from "helpers/moveItem";
import plusBlue from "data/icons/plus-blue.svg";
import deleteIcon from "data/icons/trash.svg";
import api from "services/api.service";
import closeIcon from "data/icons/close-gray.svg";
import { Loading } from "components/common";

const ContractCategory = () => {
  const [ categories, setCategories ] = useState<{ value: number, label: string }[]>([]);
  const [ name, setName ] = useState<string>("");
  const [ addMode, setAddMode ] = useState<boolean>(false);
  const [ categoryDelete, setCategoryDelete ] = useState<any | null>(null);
  const [ loading, setLoading ] = useState(false);

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };

  const onAddCategory = async () => {
    if (name) {
      setName("");
      setAddMode(false);

      const response = await api.createContractCategory(name);

      if (response.data) {
        getCategories();
      }
    }
  };

  const onMoveUp = async (index: number) => {
    if (index > 0) {
      const next = arrayMove(categories, index, index - 1);

      await api.productsReorder(next.map((i) => ({ id: i.value, name: i.label })));
      setCategories(next);
    }
  };

  const onMoveDown = async (index: number) => {
    if (index < categories.length - 1) {
      const next = arrayMove(categories, index, index + 1);

      await api.productsReorder(next.map((i) => ({ id: i.value, name: i.label })));
      setCategories(next);
    }
  };

  const handleDeleteCategory = async () => {
    setLoading(true);

    const response = await api.deleteContractCategory(categoryDelete.value);

    if (response) {
      setCategoryDelete(null);
      setLoading(false);
      getCategories();
    }
  };

  const onDeleteCategory = (category: any) => {
    setCategoryDelete(category);
  };

  const getCategories = async () => {
    const response = await api.getContractCategories();

    setCategories(response.data.results.map((el) => ({ value: el.id, label: el.name })));
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="section">
      <div className="section-title">
        Contract Category
      </div>

      <div className="section-text">
        Edit your categories according to your needs
      </div>

      <div className="section-list">
        {categories.map((category, index) => (
          <div className="section-list-item" key={ category.value }>
            <div className="section-list-item-group">
              <div
                className="section-list-item-control section-list-item-control-up"
                onClick={ () => onMoveUp(index) }
              />

              <div
                className="section-list-item-control section-list-item-control-down"
                onClick={ () => onMoveDown(index) }
              />
            </div>

            <div className="section-list-item-title">
              {category.label}
            </div>

            <Button
              type="text"
              className="section-list-item-button"
              icon={ <img src={ deleteIcon } alt=""/> }
              onClick={ () => onDeleteCategory(category) }
            >
              Delete
            </Button>
          </div>
        ))}
      </div>

      {addMode && (
        <div className="section-add">
          <Input
            value={ name }
            onChange={ onNameChange }
            placeholder="Text"
          />

          <Button
            type="primary"
            onClick={ onAddCategory }
          >
            Create
          </Button>
        </div>
      )}

      <Button
        type="dashed"
        className="section-list-button"
        icon={ <img src={ plusBlue } alt=""/> }
        onClick={ () => setAddMode(true) }
      >
        Add category
      </Button>

      <Modal
        open={ !!categoryDelete }
        closeIcon={
          <img src={ closeIcon } alt="" onClick={ () => setCategoryDelete(null) } />
        }
        footer={ null }
        centered
        className="delete-category-modal"
        width="initial"
        onCancel={ () => setCategoryDelete(null) }
      >
        <div className="delete-category-content">
          <h4>Delete product?</h4>
          <div>
            Are you sure you want to delete the {categoryDelete?.label} categories?
            All products from this category will be moved to the {"\"Undefined\""} section.
          </div>
          <div className="buttons-wrapper">
            <Button
              type="primary"
              onClick={ handleDeleteCategory }
              disabled={ loading }
            >
              <Loading isLoading={ loading }>
                Yes
              </Loading>
            </Button>
            <Button onClick={ () => setCategoryDelete(null) }>No</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContractCategory;
