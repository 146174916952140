import { useEffect, useState } from "react";

import fillIcon from "data/icons/fill-icon.svg";

import "./ColorPicker.scss";

type TPickerColor = {
  color: string;
  text: string;
}

const pickerItems: TPickerColor[] = [
  { color: "#43737D", text: "#fff" },
  { color: "#3E9CB0", text: "#fff" },
  { color: "#4CAABF", text: "#0B404B" },
  { color: "#60B8CB", text: "#fff" },
  { color: "#88CFDF", text: "#0B404B" },
  { color: "#B3DDE7", text: "#0C758C" },
  { color: "#E0F5FA", text: "#0B404B" },
  { color: "#E8F0F2", text: "#3E9CB0" },
  { color: "#F5FDFF", text: "#0B404B" },
  { color: "#FFFFFF", text: "#3E9CB0" },
];

const ColorPicker = ({ initialColor, color, setColor }: any) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const onPick = (item: TPickerColor) => {
    setIsOpen(false);
    setColor(item);
  };

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor);
    }
  }, []);

  return (
    <div className="color-picker">
      <div
        className={ `color-picker-main ${ isOpen ? "color-picker-main-active" : "" }` }
        onClick={ () => setIsOpen(!isOpen) }
      >
        <img src={ fillIcon } alt=""/>

        <div
          className="color-picker-main-item"
          style={ { backgroundColor: color.color } }
        />
      </div>

      {isOpen && (
        <div className="color-picker-menu">
          {pickerItems.map((item, i) => (
            <div
              className="color-picker-menu-item"
              key={ i }
              style={ { backgroundColor: item.color } }
              onClick={ () => onPick(item) }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
