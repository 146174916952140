import { RouteLinks } from "./router.service";
import listEquipmentWhiteIcon from "data/icons/list-equipment-white.svg";
import listEquipmentBlueIcon from "data/icons/list-equipment-blue.svg";
import listEquipmentGrayIcon from "data/icons/list-equipment-gray.svg";
import listEquipmentDarkIcon from "data/icons/list-equipment-dark-blue.svg";
import contractsWhiteIcon from "data/icons/contracts-white.svg";
import contractsBlueIcon from "data/icons/contracts-blue.svg";
import contractsGrayIcon from "data/icons/contracts-gray.svg";
import contractsDarkIcon from "data/icons/contracts-dark-blue.svg";
import casesWhiteIcon from "data/icons/cases-white.svg";
import casesBlueIcon from "data/icons/cases-blue.svg";
import casesGrayIcon from "data/icons/cases-gray.svg";
import casesDarkIcon from "data/icons/cases-dark-blue.svg";
import userManagementWhiteIcon from "data/icons/user-management-white.svg";
import userManagementBlueIcon from "data/icons/user-management-blue.svg";
import productsWhiteIcon from "data/icons/products-white.svg";
import productsBlueIcon from "data/icons/products-blue.svg";
import contractorsBlueIcon from "data/icons/contractors-blue.svg";
import contractorsWhiteIcon from "data/icons/contractors-white.svg";
import settingsBlueIcon from "data/icons/settings-blue.svg";
import settingsWhiteIcon from "data/icons/settings-white.svg";

export const formatPhoneNumber = (value: string) => {
  const numberValue = value.replace(/[^0-9]/g, "").substring(0, value.substring(1, 2) === "7" ? 11 : 12);
  if (numberValue === "") {
    return "";
  }

  let result = "";

  if (numberValue.substring(0, 1) === "3") {
    result = "+375 "
    numberValue.split("").filter((char, index) => {
      return !((index === 0 && char === "3") || (index === 1 && char === "7") || (index === 2 && char === "5"));
    }).map((char, index) => {
      if (index === 0) {
        result = result + "(" + char;
      } else if (index === 2) {
        result = result + ") " + char;
      } else if (index === 5 || index === 7) {
        result = result + "-" + char;
      } else {
        result = result + char;
      }
    })
  }

  if (numberValue.substring(0, 1) === "7") {
    result = "+7 "
    numberValue.split("").filter((char, index) => {
      return !(index === 0 && char === "7");
    }).map((char, index) => {
      if (index === 0) {
        result = result + "(" + char;
      } else if (index === 3) {
        result = result + ") " + char;
      } else if (index === 6 || index === 8) {
        result = result + "-" + char;
      } else {
        result = result + char;
      }
    })
  }

  return result;
}

export const isFuture = (date: string): boolean => {
  return new Date(+date.substring(6, 10), +date.substring(3, 5) - 1, +date.substring(0, 2)).getTime() > new Date().getTime();
}

export const authorizedPages = [
  {
    label: "List of Equipment",
    link: RouteLinks.PROTECTED.LIST_EQUIPMENT,
    icon: listEquipmentWhiteIcon,
    blueIcon: listEquipmentBlueIcon,
    grayIcon: listEquipmentGrayIcon,
    darkIcon: listEquipmentDarkIcon,
  },
  {
    label: "Contracts",
    link: RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN,
    icon: contractsWhiteIcon,
    blueIcon: contractsBlueIcon,
    grayIcon: contractsGrayIcon,
    darkIcon: contractsDarkIcon,
    children: [
      {
        label: "Invitations",
        link: RouteLinks.PROTECTED.CONTRACTS_ROUTES.INVITATION,
      },
    ],
  },
  {
    label: "Cases",
    link: RouteLinks.PROTECTED.CASES.MAIN,
    icon: casesWhiteIcon,
    blueIcon: casesBlueIcon,
    grayIcon: casesGrayIcon,
    darkIcon: casesDarkIcon,
  },
  {
    label: "Products",
    link: RouteLinks.PROTECTED.PRODUCTS,
    icon: productsWhiteIcon,
    blueIcon: productsBlueIcon,
    grayIcon: productsWhiteIcon,
    darkIcon: productsBlueIcon,
  },
  {
    label: "Contractors",
    link: RouteLinks.PROTECTED.CONTRACTORS.LIST,
    icon: contractorsWhiteIcon,
    blueIcon: contractorsBlueIcon,
    grayIcon: contractorsWhiteIcon,
    darkIcon: contractorsBlueIcon,
  },
  {
    label: "Settings",
    link: RouteLinks.PROTECTED.SETTINGS,
    icon: settingsWhiteIcon,
    blueIcon: settingsBlueIcon,
    grayIcon: settingsWhiteIcon,
    darkIcon: settingsBlueIcon,
  },
];

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[ 1 ];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function(c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));

  return JSON.parse(jsonPayload);
}

export const getClientRoleTitle = (role: "provider_admin" | "provider_staff" | "provider_engineer" | "customer_admin" | "customer_user"): string => {
  switch (role) {
    case "provider_admin":
      return "Provider Admin"
    case "provider_staff":
      return "Provider Staff"
    case "provider_engineer":
      return "Provider Engineer"
    case "customer_admin":
      return "Customer Admin"
    case "customer_user":
      return "Customer User"
    default:
      return "-/-"
  }
}

export const formatDateForApi = (date: string): string => {
  return date === "" || date === null ? "" : `${ new Date(date).getFullYear() }-${ formatDateItem(new Date(date).getMonth() + 1) }-${ formatDateItem(new Date(date).getDate()) }`
}

export const formatDateItem = (item: number): string => {
  return item < 10 ? `0${ item }` : item.toString();
}

export const downloadFromLink = (link: string) => {
  window.open(link);
}

export const extraPortion = 50;
export const extraLimit = 500;
