import { FC } from "react";

import cardViewGreenIcon from "data/icons/card-view-green.svg";
import cardViewWhiteIcon from "data/icons/card-view-white.svg";
import tableViewGreenIcon from "data/icons/table-view-green.svg";
import tableViewWhiteIcon from "data/icons/table-view-white.svg";

import "./TableCardSwitch.scss";

interface IProps {
  isOn: boolean;
  handleSwitch: () => void;
}

const TableCardSwitch: FC<IProps> = ({ isOn, handleSwitch }) => {
  return (
    <div className="table-card-switch-wrapper" onClick={ handleSwitch }>
      <div className={ `table-card-switcher ${ isOn ? "" : "off" }` } />
      <img className="table-card-image" src={ isOn ? tableViewWhiteIcon : tableViewGreenIcon } alt="" />
      <img className="table-card-image" src={ isOn ? cardViewGreenIcon : cardViewWhiteIcon } alt="" />
    </div>
  )
}

export default TableCardSwitch;
