import { FC, useRef, useState } from "react";

import { Button } from "antd";
import ReactMarkdown from "react-markdown";

import { IActionsLog } from "store/cases/cases.types";
import arrowIcon from "data/icons/arrow-blue.svg";

interface IProps {
  log: IActionsLog;
}

const LogItem: FC<IProps> = ({ log }) => {
  const textRef = useRef<HTMLParagraphElement>();
  const [ isFullHeight, setIsFullHeight ] = useState(false);

  return (
    <div className="comment-wrapper">
      <div className="comment-info-wrapper">
        <div className="comment-title-wrapper">
          <p className="date">{ log.at }</p>
        </div>

        <div className={ `comment-content-wrapper ${ isFullHeight ? "full" : "" }` }>
          { textRef.current?.offsetHeight && textRef.current.offsetHeight >= 170 && (
            <div className="show-more-button-wrapper">
              <Button
                type="text"
                className="show-more-button"
                onClick={ () => setIsFullHeight(!isFullHeight) }
              >
                Show more

                <img src={ arrowIcon } alt="" />
              </Button>
            </div>
          ) }

            <ReactMarkdown>{log.text}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export default LogItem;
