import { useState } from "react";

import {
  GeneralSettings,
  SettingsHeader,
  Team,
  // EmailSender,
} from "components/settings";

import "./SettingsPage.scss";

const SettingsPage = () => {
  const [ activeTab, setActiveTab ] = useState("general-settings");

  return (
    // <div className="settings-page">
    <div className="default-page-wrapper">
      <SettingsHeader activeTab={ activeTab } setActiveTab={ setActiveTab } />
      {activeTab === "general-settings" && <GeneralSettings />}
      {activeTab === "team" && <Team />}
      {/*{activeTab === "email-sender" && <EmailSender />}*/}
    </div>
  );
};

export default SettingsPage;
