export const RouteLinks = {
  AUTH: "/auth",
  MAIN: "/",
  MAIN_V2: "/v2",
  SUPPORT: "/support",
  INVITE_COMPLETE: "/invite/complete",
  INVITE_ORGANIZATION_COMPLETE: "/invite/organization/complete",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_CONFIRM: "/user/reset-password/confirm",
  PROTECTED: {
    LIST_EQUIPMENT: "/list-equipment",
    CASES: {
      MAIN: "/cases",
      CASE: "/cases/case",
    },
    ORDER_ITEMS: "/order-items",
    SETTINGS: "/settings",
    CONTRACTS_ROUTES: {
      MAIN: "/contracts",
      CONTRACT: "/contracts/contract",
      NEW: "/contracts/new",
      INVITATION: "/contracts/invitation",
    },
    DOWNLOADS: "/downloads",
    DOWNLOAD_ROUTES: {
      MAIN: "/downloads",
      FILE: "/downloads/file",
      ADD_FILE: "/downloads/add-file",
    },
    STORAGE: "/storage",
    PRODUCTS: "/products",
    CONTRACTORS: {
      LIST: "/contractors",
      NEW: "/contractors/new",
    },
  },
};
