import { useCallback, useState } from "react";

type TFunction = (...args: any[]) => any;

export const useLoading = (callback: TFunction): [ TFunction, boolean ] => {
  const [ isLoading, setIsLoading ] = useState(true);

  const wrappedFunction = useCallback(async (...props: any) => {
    setIsLoading(true);

    try {
      return await callback(...props);
    } finally {
      setIsLoading(false);
    }
  }, [ callback ]);

  return [ wrappedFunction, isLoading ];
};
