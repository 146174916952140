import { IInvitation, IListInvitationFilter } from "./invitations.types";

export const ActionTypes = {
  GET_INVITATIONS_REQUEST: "[LIST EQUIPMENT] GET INVITATIONS REQUEST",
  GET_INVITATIONS_SUCCESS: "[LIST EQUIPMENT] GET INVITATIONS SUCCESS",
  GET_INVITATIONS_FAIL: "[LIST EQUIPMENT] GET INVITATIONS FAIL",
  UPDATE_INVITATIONS_FILTER: "[LIST EQUIPMENT] UPDATE INVITATIONS FILTER",
  UPDATE_INVITATIONS_PAGINATION: "[LIST EQUIPMENT] UPDATE INVITATIONS PAGINATION",
  UPDATE_INVITATIONS_SORT: "[LIST EQUIPMENT] UPDATE INVITATIONS SORT",
  GET_EXTRA_INVITATIONS_REQUEST: "[LIST EQUIPMENT] GET EXTRA INVITATIONS REQUEST",
  GET_EXTRA_INVITATIONS_SUCCESS: "[LIST EQUIPMENT] GET EXTRA INVITATIONS SUCCESS",
  GET_EXTRA_INVITATIONS_FAIL: "[LIST EQUIPMENT] GET EXTRA INVITATIONS FAIL",
} as const;

export const getInvitationsRequest = () => ({
  type: ActionTypes.GET_INVITATIONS_REQUEST,
})

export const getInvitationsSuccess = (invitations: IInvitation[], count: number) => ({
  type: ActionTypes.GET_INVITATIONS_SUCCESS,
  payload: { invitations, count },
})

export const getInvitationsFail = () => ({
  type: ActionTypes.GET_INVITATIONS_FAIL,
})

export const updateInvitationsFilter = (filter: IListInvitationFilter) => ({
  type: ActionTypes.UPDATE_INVITATIONS_FILTER,
  payload: filter,
})

export const updateInvitationsPagination = (page: number) => ({
  type: ActionTypes.UPDATE_INVITATIONS_PAGINATION,
  payload: { page },
})

export const getExtraInvitationsRequest = () => ({
  type: ActionTypes.GET_EXTRA_INVITATIONS_REQUEST,
})

export const getExtraInvitationsSuccess = (invitations: IInvitation[], count: number) => ({
  type: ActionTypes.GET_EXTRA_INVITATIONS_SUCCESS,
  payload: { invitations, count },
})

export const getExtraInvitationsFail = () => ({
  type: ActionTypes.GET_EXTRA_INVITATIONS_FAIL,
})
