import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getProductsSelector } from "store/products/products.selector";
import { updateProductsPagination } from "store/products/products.actions";
import { getProducts } from "store/products/products.thunks";
import { extraLimit } from "services/data.service";
import { Loading } from "components/common";

const ProductsFooter: FC = () => {
  const dispatch = useAppDispatch();
  const { pagination, isExtraProductsLoading } = useAppSelector(getProductsSelector);

  const handleUpdatePagination = (page: number) => {
    dispatch(updateProductsPagination(page));
    dispatch(getProducts());
  }

  return (
    <div className="default-footer-wrapper">
      <Loading isLoading={ isExtraProductsLoading } isColored />
      <Pagination
        total={ pagination.count }
        showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
        pageSize={ extraLimit }
        current={ pagination.page }
        onChange={ handleUpdatePagination }
        showSizeChanger={ false }
      />
    </div>
  )
}

export default ProductsFooter;
