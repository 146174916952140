import { IDownload, IDownloadsFilter } from "./downloads.types";

export const ActionTypes = {
  GET_DOWNLOADS_REQUEST: "[DOWNLOADS] GET DOWNLOADS REQUEST",
  GET_DOWNLOADS_SUCCESS: "[DOWNLOADS] GET DOWNLOADS SUCCESS",
  GET_DOWNLOADS_FAIL: "[DOWNLOADS] GET DOWNLOADS FAIL",
  UPDATE_FILTER: "[DOWNLOADS] UPDATE FILTER",
  UPDATE_PAGINATION: "[DOWNLOADS] UPDATE PAGINATION",
  SWITCH_DATA_DISPLAY_TYPE: "[DOWNLOADS] SWITCH DATA DISPLAY TYPE",
} as const;

export const getDownloadsRequest = () => ({
  type: ActionTypes.GET_DOWNLOADS_REQUEST,
})

export const getDownloadsSuccess = (downloads: IDownload[], count: number) => ({
  type: ActionTypes.GET_DOWNLOADS_SUCCESS,
  payload: { downloads, count },
})

export const getDownloadsFail = () => ({
  type: ActionTypes.GET_DOWNLOADS_FAIL,
})

export const updateDownloadsFilter = (filter: IDownloadsFilter) => ({
  type: ActionTypes.UPDATE_FILTER,
  payload: filter,
})

export const updateDownloadsPagination = (pagination: { page: number, rowsPerPage: number }) => ({
  type: ActionTypes.UPDATE_PAGINATION,
  payload: pagination,
})

export const switchDataDisplayType = (type: "table" | "cards") => ({
  type: ActionTypes.SWITCH_DATA_DISPLAY_TYPE,
  payload: type,
})
