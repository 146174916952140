import { Reducer } from "react";

import { IProductsState, TProductsAction } from "./products.types";
import { ActionTypes } from "./products.actions";
import { extraPortion } from "services/data.service";

export const initialState: IProductsState = {
  products: [],
  isProductsLoading: false,
  isExtraProductsLoading: false,
  pagination: {
    page: 1,
    rowsPerPage: extraPortion,
    count: 0,
  },
  categories: [],
  isProductCategoriesLoading: false,
  filter: {
    search: "",
    category: null,
  },
}

const productsReducer: Reducer<IProductsState, TProductsAction> = (prevState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCTS_REQUEST:
      return {
        ...prevState,
        isProductsLoading: true,
      }
    case ActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...prevState,
        isProductsLoading: false,
        products: action.payload.products,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_PRODUCTS_FAIL:
      return {
        ...prevState,
        isProductsLoading: false,
      }
    case ActionTypes.UPDATE_PRODUCTS_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: action.payload.page,
        },
      }
    case ActionTypes.GET_PRODUCTS_CATEGORIES_REQUEST:
      return {
        ...prevState,
        isProductCategoriesLoading: true,
      }
    case ActionTypes.GET_PRODUCTS_CATEGORIES_SUCCESS:
      return {
        ...prevState,
        categories: action.payload,
        isProductCategoriesLoading: false,
      }
    case ActionTypes.GET_PRODUCTS_CATEGORIES_FAIL:
      return {
        ...prevState,
        isProductCategoriesLoading: false,
      }
    case ActionTypes.UPDATE_PRODUCTS_FILTER:
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          ...action.payload,
        },
      }
    case ActionTypes.UPDATE_PRODUCT:
      return {
        ...prevState,
        products: (prevState.filter.category === null || prevState.filter.category === action.payload.category.id)
          ? prevState.products.map((item) => item.id === action.payload.id ? action.payload : item)
          : prevState.products.filter((item) => item.id !== action.payload.id),
      }
    case ActionTypes.GET_EXTRA_PRODUCTS_REQUEST:
      return {
        ...prevState,
        isExtraProductsLoading: true,
      }
    case ActionTypes.GET_EXTRA_PRODUCTS_SUCCESS:
      return {
        ...prevState,
        products: [
          ...prevState.products,
          ...action.payload.products,
        ],
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
          rowsPerPage: prevState.pagination.rowsPerPage + extraPortion,
        },
        isExtraProductsLoading: false,
      }
    case ActionTypes.GET_EXTRA_PRODUCTS_FAIL:
      return {
        ...prevState,
        isExtraProductsLoading: false,
      }
    default:
      return prevState;
  }
}

export default productsReducer;
