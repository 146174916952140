import { FC, useState } from "react";

import { Button, message, Modal } from "antd";

import copyBlueIcon from "data/icons/copy-blue.svg";
import { InviteForm } from "components/common";
import api from "services/api.service";

import "./CreateInviteModal.scss";

const CreateInviteModal: FC = () => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleCopyLink = async () => {
    try {
      const response = await api.usersInviteLink();

      navigator.clipboard.writeText(response.data.url as string);
      message.success("Link copied!")
    } catch (e) {
      message.error("Something went wrong!");
    }
  };

  return (
    <>
      <Button
        className="create-invite-button"
        type="primary"
        onClick={ () => setIsOpen(true) }
        style={ { marginRight: "20px" } }
      >
        Invite
      </Button>

      <span
        className="create-invite-copy-button"
        onClick={ handleCopyLink }
      >
        <img src={ copyBlueIcon } alt=""/>
        Copy link
      </span>

      <Modal
        className="create-invite-form"
        open={ isOpen }
        onCancel={ () => setIsOpen(false) }
        footer={ null }
      >
        { isOpen && (
          <InviteForm
            afterEvent={ () => setIsOpen(false) }
          />
        ) }
      </Modal>
    </>
  )
}

export default CreateInviteModal;
