import { Reducer } from "react";

import { IUserManagementState, TUserManagementAction } from "./userManagement.types";
import { ActionTypes } from "./userManagement.actions";

export const initialState: IUserManagementState = {
  clients: [],
  isClientsLoading: false,
  filter: {
    type: "new",
    search: "",
  },
  pagination: {
    page: 1,
    rowsPerPage: 20,
    count: 0,
  },
  sort: {
    direction: "ascend",
    column: "first_name",
  },
  newClientsCount: 0,
};

const userManagementReducer: Reducer<IUserManagementState, TUserManagementAction> = (prevState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CLIENTS_REQUEST:
      return {
        ...prevState,
        isClientsLoading: true,
      }
    case ActionTypes.GET_CLIENTS_SUCCESS:
      return {
        ...prevState,
        isClientsLoading: false,
        clients: action.payload.clients,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_CLIENTS_FAIL:
      return {
        ...prevState,
        isClientsLoading: false,
      }
    case ActionTypes.UPDATE_CLIENTS_FILTER:
      return {
        ...prevState,
        filter: action.payload,
      }
    case ActionTypes.UPDATE_CLIENTS_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          ...action.payload,
        },
      }
    case ActionTypes.UPDATE_CLIENT:
      return {
        ...prevState,
        clients: prevState.clients.map((client) => client.id === action.payload.id ? action.payload : client),
      }
    case ActionTypes.GET_NEW_CLIENTS_COUNT:
      return {
        ...prevState,
        newClientsCount: action.payload,
      }
    case ActionTypes.UPDATE_CLIENTS_SORT:
      return {
        ...prevState,
        sort: action.payload,
      }
    default:
      return prevState;
  }
}

export default userManagementReducer;
