import { FC, useEffect } from "react";

import { ColumnsType } from "antd/es/table";
import { Button, Table, Tooltip } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getStorageSelector } from "store/storage/storage.selector";
import { IStorageItem } from "store/storage/storage.types";
import { Loading } from "components/common";
import "components/cases/CasesTable/CasesTable.scss";
import { getExtraStorage } from "store/storage/storage.thunks";
import { extraLimit } from "services/data.service";

import "./StorageTable.scss";

const StorageTable: FC = () => {
  const dispatch = useAppDispatch();
  const { storage, isStorageLoading, pagination, isExtraStorageLoading } = useAppSelector(getStorageSelector);

  const columns: ColumnsType<IStorageItem> = [
    {
      title: "Warehouse ID",
      dataIndex: "storage_id",
      key: "storage_id",
      width: 200,
      render: (value, record) => record.name === "add_more"
        ? <Button type="text" onClick={ () => dispatch(getExtraStorage()) } disabled={ isExtraStorageLoading }>+ Show more</Button>
        : <p className="active">{ value }</p>,
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 7 : 1,
        style: { textAlign: item.name === "add_more" ? "center" : "initial" },
      }),
    },
    {
      title: "Labels",
      dataIndex: "labels",
      key: "labels",
      className: "labels-table-cell",
      render: (value, record) => record.name === "add_more" ? null : (
        <Tooltip title={ record.labels.map((item, index) => item + (index === record.labels.length - 1 ? "" : ", ")) }>
          <p className="active">{ record.labels.map((item, index) => item + (index === record.labels.length - 1 ? "" : ", ")) }</p>
        </Tooltip>
      ),
      width: 200,
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "name-table-cell",
      render: (value, record) => record.name === "add_more" ? null : (
        <Tooltip title={ value }>
          <p className="active thin">{ value }</p>
        </Tooltip>
      ),
      width: 400,
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      key: "serial_number",
      width: 150,
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Equipment Type",
      dataIndex: "equipment_type",
      key: "equipment_type",
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 100,
      onCell: (item) => ({
        colSpan: item.name === "add_more" ? 0 : 1,
      }),
    },
  ]

  useEffect(() => {
    const table = document.getElementById("storage-table");
    if (table) {
      const body: any = table.getElementsByClassName("ant-table-body");

      if (body.length > 0) {
        body[ 0 ].onscroll = (e: any) => {
          const tbody: any = table.getElementsByClassName("ant-table-tbody");
          if (tbody.length > 0) {
            if (tbody[ 0 ].offsetHeight === e.target.scrollTop + e.target.offsetHeight) {
              dispatch(getExtraStorage());
            }
          }
        };
      }
    }
  }, [ isStorageLoading ])

  return (
    <Loading isLoading={ isStorageLoading } isColored isFullWidth height={ 200 }>
      <Table
        className="cases-table storage-table"
        id="storage-table"
        columns={ columns }
        dataSource={ (storage.length >= extraLimit || ((pagination.page - 1) * extraLimit + storage.length) === pagination.count) ? storage : [ ...storage, { name: "add_more" } as IStorageItem ] }
        rowKey={ (item) => item.id }
        pagination={ false }
        scroll={ { y: window.innerHeight - 226 } }
        tableLayout="auto"
      />
    </Loading>
  )
}

export default StorageTable;
