import { FC } from "react";

import { NavLink } from "react-router-dom";

import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { RouteLinks } from "services/router.service";
import { LoginForm } from "components/common";

import "./AuthorizationPage.scss";

const AuthorizationPage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);

  return (
    <div className={ `authorization-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <LoginForm />

      <p className="bottom-description">
        Don{ "'" }t have an account yet? Sign up <NavLink to={ RouteLinks.INVITE_COMPLETE }>here</NavLink>
        {" "}or navigate the <NavLink to={ RouteLinks.MAIN }>main page</NavLink>.
      </p>
    </div>
  )
}

export default AuthorizationPage;
