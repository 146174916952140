import { message } from "antd";

import { TAppDispatch } from "store";
import {
  authorizeUserFail,
  authorizeUserRequest,
  authorizeUserSuccess,
  getMeta,
  getNotificationsFail,
  getNotificationsRequest,
  getNotificationsSuccess,
  getOrganizationDataSuccess,
  getUserDataFail,
  getUserDataRequest,
  getUserDataSuccess,
  logoutUser,
} from "./user.actions";
import api from "services/api.service";
import { formatPhoneNumber } from "services/data.service";

export const authorizeUser = (
  email: string,
  password: string,
  onError: (error: string) => void,
  onSuccess: () => void,
) => async (dispatch: TAppDispatch) => {
  dispatch(authorizeUserRequest())
  try {
    const response = await api.authorizeUser(email, password);

    localStorage.setItem("AUTH_TOKEN", response.data.access);
    dispatch(authorizeUserSuccess());
    onSuccess();
  } catch (e: any) {
    dispatch(authorizeUserFail())
    // onError(e.response.data.error || e.response.data.detail || "Something went wrong!");
    message.error(e.response.data.error || e.response.data.detail || e.error || "Something went wrong!");
  }
}

export const getUserData = (onFailure: () => void) => async (dispatch: TAppDispatch) => {
  dispatch(getUserDataRequest());
  try {
    const response = await api.getUserData();
    const meta = await api.getMeta();

    dispatch(getMeta(meta.data));
    dispatch(getUserDataSuccess({
      ...response.data,
      phone_number: response.data.phone_number ? formatPhoneNumber(response.data.phone_number) : "",
    }));
  } catch (e: any) {
    dispatch(logoutUser());
    localStorage.removeItem("AUTH_TOKEN");

    onFailure();
    dispatch(getUserDataFail());
  }
}

export const getOrganizationData = () => async (dispatch: TAppDispatch) => {
  try {
    const response = await api.getOrganizationData();

    dispatch(getOrganizationDataSuccess(response.data));
  } catch (e: any) {
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getNotifications = () => async (dispatch: TAppDispatch) => {
  dispatch(getNotificationsRequest());
  try {
    const response = await api.getNotifications();

    dispatch(getNotificationsSuccess(response.data.results));
  } catch (e: any) {
    dispatch(getNotificationsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}
