import { FC, useEffect } from "react";

import { StorageHeader, StorageTable, StoragePagination } from "components/storage";
import { useAppDispatch } from "store";
import { getStorage } from "store/storage/storage.thunks";

const StoragePage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStorage());
  }, [])

  return (
    <div className="default-page-wrapper">
      <StorageHeader />
      <StorageTable />
      <StoragePagination />
    </div>
  )
}

export default StoragePage;
