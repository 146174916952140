import {
  ContractInvitationsHeader,
  ContractInvitationsTable,
  ContractInvitationsPagination,
} from "components/contracts";

const ContractInvitationsPage = () => {
  return (
    <div className="default-page-wrapper">
      <ContractInvitationsHeader/>
      <ContractInvitationsTable />
      <ContractInvitationsPagination />
    </div>
  );
};

export default ContractInvitationsPage;
