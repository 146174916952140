import { FC, useEffect } from "react";

import { useAppDispatch } from "store";
import { getContractors } from "store/contractors/contractors.thunks";
import { updateContractorsFilter, updateContractorsPagination } from "store/contractors/contractors.actions";
import { initialState } from "store/contractors/contractors.reducer";
import {
  ContractorsFooter,
  ContractorsHeader,
  ContractorsTable,
} from "components/contractors";

const ContractorsPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getContractors());

    return () => {
      dispatch(updateContractorsPagination(initialState.pagination.page));
      dispatch(updateContractorsFilter(initialState.filter));
    }
  }, [])

  return (
    <div className="default-page-wrapper">
      <ContractorsHeader />
      <ContractorsTable />
      <ContractorsFooter />
    </div>
  )
}

export default ContractorsPage;
