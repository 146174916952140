import { FC, useEffect } from "react";

import { Button, Popover, message } from "antd";
import { useLocation } from "react-router-dom";

import bellIcon from "data/icons/bell.svg";
import SDPLabelIcon from "data/icons/SDP-short.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { Loading } from "components/common";
import { getNotifications } from "store/user/user.thunks";
import api from "services/api.service";
import { getNotificationsSuccess } from "store/user/user.actions";

import "./Notifications.scss";

const getTime = (timestamp: number): string => {
  const now = new Date().getTime();

  if (now - timestamp <= 60000) {
    const secs = (now - timestamp)/1000;

    return secs.toFixed() + " sec ago"
  }

  if (now - timestamp <= 3600000) {
    const min = (now - timestamp)/60000;

    return min.toFixed() + " min ago"
  }

  if (now - timestamp <= 86400000) {
    const hrs = (now - timestamp)/3600000;

    return hrs.toFixed() + " hr ago"
  }

  if (now - timestamp <= 604800000) {
    const days = (now - timestamp)/86400000;

    return days.toFixed() + " d ago"
  }

  if (now - timestamp <= 2592000000) {
    const weeks = (now - timestamp)/604800000;

    return weeks.toFixed() + " w ago"
  }

  if (now - timestamp <= 31556952000) {
    const months = (now - timestamp)/2592000000;

    return months.toFixed() + " m ago"
  }

  const years = (now - timestamp)/31556952000;

  return years.toFixed() + " y ago"
}

const getMessage = (message: string): Array<string | JSX.Element> => {
  const parts = message.split("/b");
  return parts.map((part, index) => {
    return index % 2 ? <b key={ Math.random() }>{ part }</b> : <span key={ Math.random() }>{ part }</span>;
  })
}

const Notifications: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { notifications, isNotificationsLoading } = useAppSelector(getUserSelector);

  useEffect(() => {
    // dispatch(getNotifications());
  }, [ location.pathname ]);

  const handleClear = async () => {
    try {
      await api.clearNotifications();

      dispatch(getNotificationsSuccess([]));
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const popoverContent = () => (
    <div className="notes-popover-wrapper">
      <h3>
        Notifications
        <Button
          type="text"
          onClick={ handleClear }
        >
          Clear all
        </Button>
      </h3>
      <div className="notifications-inner-wrapper">
        { notifications.map((note) => (
          <div key={ note.id } className="note-item">
            <div className="note-item-icon">
              <img src={ SDPLabelIcon } alt="" />
            </div>
            <div className="note-item-description">
              <div className="note-item-info">
                <p className="note-item-sender">{ note.sender }</p>
                <p className="note-item-timestamp">{ getTime(+note.timestamp * 1000) }</p>
              </div>
              <p className="note-item-message">{ getMessage(note.message) }</p>
            </div>
          </div>
        )) }
      </div>
      { notifications.length === 0 && (
        <p className="empty-title">There are no notifications for you.</p>
      ) }
    </div>
  )

  return (
    <div className="notifications-wrapper">
      <Loading isLoading={ isNotificationsLoading } isColored width={ 24 } height={ 24 }>
      <Popover placement="bottomRight" content={ popoverContent } trigger="click">
        <Button
          className="notifications-bell"
          type="text"
          shape="circle"
          icon={ <img src={ bellIcon } alt="" /> }
        />
      </Popover>
        { notifications.length > 0 && <p className="notifications-count">{ notifications.length }</p> }
      </Loading>
    </div>
  )
}

export default Notifications;
