import TeamFilter from "./TeamFilter";
import TeamTable from "./TeamTable";
import TeamPagination from "./TeamPagination";

const Team = () => {
  return (
    <>
      <TeamFilter/>
      <TeamTable/>
      <TeamPagination/>
    </>
  );
};

export default Team;
