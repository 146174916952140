import { IStorageFilter, IStorageItem } from "./storage.types";

export const ActionTypes = {
  GET_STORAGE_REQUEST: "[STORAGE] GET STORAGE REQUEST",
  GET_STORAGE_SUCCESS: "[STORAGE] GET STORAGE SUCCESS",
  GET_STORAGE_FAIL: "[STORAGE] GET STORAGE FAIL",
  GET_EXTRA_STORAGE_REQUEST: "[STORAGE] GET EXTRA STORAGE REQUEST",
  GET_EXTRA_STORAGE_SUCCESS: "[STORAGE] GET EXTRA STORAGE SUCCESS",
  GET_EXTRA_STORAGE_FAIL: "[STORAGE] GET EXTRA STORAGE FAIL",
  UPDATE_STORAGE_FILTER: "[STORAGE] UPDATE STORAGE FILTER",
  UPDATE_STORAGE_PAGINATION: "[STORAGE] UPDATE STORAGE PAGINATION",
} as const;

export const getStorageRequest = () => ({
  type: ActionTypes.GET_STORAGE_REQUEST,
})

export const getStorageSuccess = (storage: IStorageItem[], count: number) => ({
  type: ActionTypes.GET_STORAGE_SUCCESS,
  payload: { storage, count },
})

export const getStorageFail = () => ({
  type: ActionTypes.GET_STORAGE_FAIL,
})

export const updateStorageFilter = (filter: Partial<IStorageFilter>) => ({
  type: ActionTypes.UPDATE_STORAGE_FILTER,
  payload: filter,
})

export const updateStoragePagination = (page: number, rowsPerPage: number) => ({
  type: ActionTypes.UPDATE_STORAGE_PAGINATION,
  payload: { page, rowsPerPage },
})

export const getExtraStorageRequest = () => ({
  type: ActionTypes.GET_EXTRA_STORAGE_REQUEST,
})

export const getExtraStorageSuccess = (storage: IStorageItem[], count: number) => ({
  type: ActionTypes.GET_EXTRA_STORAGE_SUCCESS,
  payload: { storage, count },
})

export const getExtraStorageFail = () => ({
  type: ActionTypes.GET_EXTRA_STORAGE_FAIL,
})
