import { useState } from "react";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import backIcon from "data/icons/arrow-left.svg";
import ContractCreateForm from "components/contracts/ContractCreateForm";
import ContractCreateTable from "components/contracts/ContractCreateTable";
import ContractCreateStatus from "components/contracts/ContractCreateStatus";
import ContractTabs from "components/contracts/ContractTabs";
import { RouteLinks } from "services/router.service";
import { IContractCreate } from "store/contracts/contracts.types";
import api from "services/api.service";

import "./ContractNewPage.scss";

const ContractNewPage = () => {
  const navigate = useNavigate();

  const [ products, setProducts ] = useState<IContractCreate[]>([]);
  const [ edit, setEdit ] = useState(false);

  const [ fileId, setFileId ] = useState<number>();
  const [ contractId, setContractId ] = useState<number>();

  const handleSave = async () => {
    if (fileId && contractId) {
      try {
        await api.productsBulk(products);
        await api.deserializeFileProcess(fileId, contractId);

        navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + contractId);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onNavigateBack = () => {
    if (products.length > 0) {
      // setProducts([]);
    } else {
      navigate(-1);
    }

    setEdit(false);
  };

  return (
    <div className="contract-new-page-wrapper">
      <div className="contract-new-header">
        <Button
          type="link"
          onClick={ onNavigateBack }
        >
          <img src={ backIcon } alt="" />
        </Button>
        <div className="contract-new-header-title">
          Create contract
        </div>
      </div>

      {/*<div className="contract-new-title">*/}
      {/*  Create a new contract*/}
      {/*</div>*/}

      {/*<div className="contract-new-subtitle">*/}
      {/*  Create the contract terms with the guided process*/}
      {/*</div>*/}

      <ContractTabs />

      {/*{!contractId && !edit && (*/}
      {/*  <ContractCreateForm*/}
      {/*    fileId={ fileId }*/}
      {/*    setFileId={ setFileId }*/}
      {/*    setContractId={ setContractId }*/}
      {/*    setProducts={ setProducts }*/}
      {/*  />*/}
      {/*)}*/}

      {/*{contractId && !edit && (*/}
      {/*  <ContractCreateStatus*/}
      {/*    contractId={ contractId }*/}
      {/*    productsLength={ products.length }*/}
      {/*    setEdit={ setEdit }*/}
      {/*  />*/}
      {/*)}*/}

      {/*{products.length > 0 && edit && (*/}
      {/*  <>*/}
      {/*    <Button*/}
      {/*      type="primary"*/}
      {/*      className="contract-new-header-button"*/}
      {/*      onClick={ handleSave }*/}
      {/*      // TODO: REVIEW*/}
      {/*      disabled={ products?.some( product => ! product?.category ) }*/}
      {/*    >*/}
      {/*      Save*/}
      {/*    </Button>*/}
      {/*    <ContractCreateTable products={ products } setProducts={ setProducts } />*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};

export default ContractNewPage;
