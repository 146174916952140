import { Reducer } from "react";

import { IContractsState, TContractsAction } from "./contracts.types";
import { ActionTypes } from "./contracts.actions";
import { extraPortion } from "services/data.service";

export const initialState: IContractsState = {
  contracts: [],
  categories: [],
  isContractsLoading: false,
  filter: {
    search: "",
    category: null,
  },
  pagination: {
    page: 1,
    rowsPerPage: 20,
    count: 0,
  },
  currentContract: null,
  isCurrentContractLoading: false,
  equipments: [],
  isEquipmentsLoading: false,
  isExtraEquipmentsLoading: false,
  equipmentsFilter: {
    search: "",
    type: "all",
  },
  equipmentsPagination: {
    page: 1,
    rowsPerPage: extraPortion,
    count: 0,
  },
  sort: {
    column: "contract_number",
    direction: "ascend",
  },
  equipmentsSort: {
    column: "product_number",
    direction: "ascend",
  },
}

const contractsReducer: Reducer<IContractsState, TContractsAction> = (prevState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTRACTS_REQUEST:
      return {
        ...prevState,
        isContractsLoading: true,
      }
    case ActionTypes.GET_CONTRACTS_SUCCESS:
      return {
        ...prevState,
        isContractsLoading: false,
        contracts: action.payload.contracts,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_CONTRACTS_FAIL:
      return {
        ...prevState,
        isContractsLoading: false,
      }
    case ActionTypes.UPDATE_CONTRACTS_FILTER:
      return {
        ...prevState,
        filter: action.payload,
      }
    case ActionTypes.UPDATE_CONTRACTS_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          ...action.payload,
        },
      }
    case ActionTypes.GET_CONTRACTS_CATEGORIES_REQUEST:
      return {
        ...prevState,
        isProductCategoriesLoading: true,
      }
    case ActionTypes.GET_CONTRACTS_CATEGORIES_SUCCESS:
      return {
        ...prevState,
        categories: action.payload,
        isProductCategoriesLoading: false,
      }
    case ActionTypes.GET_CONTRACTS_CATEGORIES_FAIL:
      return {
        ...prevState,
        isProductCategoriesLoading: false,
      }
    case ActionTypes.GET_CURRENT_CONTRACT_REQUEST:
      return {
        ...prevState,
        isCurrentContractLoading: true,
      }
    case ActionTypes.GET_CURRENT_CONTRACT_SUCCESS:
      return {
        ...prevState,
        isCurrentContractLoading: false,
        currentContract: action.payload,
      }
    case ActionTypes.GET_CURRENT_CONTRACT_FAIL:
      return {
        ...prevState,
        isCurrentContractLoading: false,
      }
    case ActionTypes.GET_CONTRACT_EQUIPMENTS_REQUEST:
      return {
        ...prevState,
        isEquipmentsLoading: true,
      }
    case ActionTypes.GET_CONTRACT_EQUIPMENTS_SUCCESS:
      return {
        ...prevState,
        isEquipmentsLoading: false,
        equipments: action.payload.equipments,
        equipmentsPagination: {
          ...prevState.equipmentsPagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_CONTRACT_EQUIPMENTS_FAIL:
      return {
        ...prevState,
        isEquipmentsLoading: false,
      }
    case ActionTypes.CLEAR_CURRENT_CONTRACT:
      return {
        ...prevState,
        currentContract: null,
        equipments: [],
        isCurrentContractLoading: false,
        isEquipmentsLoading: false,
      }
    case ActionTypes.UPDATE_ALIAS:
      return {
        ...prevState,
        contracts: prevState.contracts.map((contract) => {
          return contract.id === action.payload.contract.id ? {
            ...contract,
            alias: {
              ...contract.alias,
              alias: action.payload.alias,
            },
          } : contract;
        }),
      }
    case ActionTypes.UPDATE_EQUIPMENTS_FILTER:
      return {
        ...prevState,
        equipmentsFilter: action.payload,
      }
    case ActionTypes.UPDATE_EQUIPMENTS_PAGINATION:
      return {
        ...prevState,
        equipmentsPagination: {
          ...prevState.equipmentsPagination,
          ...action.payload,
        },
      }
    case ActionTypes.UPDATE_CONTRACTS_SORT:
      return {
        ...prevState,
        sort: action.payload,
      }
    case ActionTypes.UPDATE_EQUIPMENTS_SORT:
      return {
        ...prevState,
        equipmentsSort: action.payload,
      }
    case ActionTypes.UPDATE_CURRENT_CONTRACT_ALIAS:
      return {
        ...prevState,
        currentContract: prevState.currentContract ? {
          ...prevState.currentContract,
          information: action.payload,
        } : prevState.currentContract,
      }
    case ActionTypes.GET_EXTRA_CONTRACT_EQUIPMENTS_REQUEST:
      return {
        ...prevState,
        isExtraEquipmentsLoading: true,
      }
    case ActionTypes.GET_EXTRA_CONTRACT_EQUIPMENTS_SUCCESS:
      return {
        ...prevState,
        isExtraEquipmentsLoading: false,
        equipments: [
          ...prevState.equipments,
          ...action.payload.items,
        ],
        equipmentsPagination: {
          ...prevState.equipmentsPagination,
          count: action.payload.count,
          rowsPerPage: prevState.equipmentsPagination.rowsPerPage + extraPortion,
        },
      }
    case ActionTypes.GET_EXTRA_CONTRACT_EQUIPMENTS_FAIL:
      return {
        ...prevState,
        isExtraEquipmentsLoading: false,
      }
    default:
      return prevState;
  }
}

export default contractsReducer;
