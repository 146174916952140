import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Link } from "react-router-dom";
import { Button, Input } from "antd";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import { getContractorsSelector } from "store/contractors/contractors.selector";
import { updateContractorsFilter, updateContractorsPagination } from "store/contractors/contractors.actions";
import { getContractors } from "store/contractors/contractors.thunks";
import searchIcon from "data/icons/search.svg";
import "components/contracts/ContractsHeader";
import { RouteLinks } from "services/router.service";

const ContractorsHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(getContractorsSelector);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateContractorsFilter({
      search: e.target.value,
    }))
  }

  const updateInfo = () => {
    dispatch(updateContractorsPagination(1));
    dispatch(getContractors());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="contracts-header-wrapper">
      <Link to={ RouteLinks.PROTECTED.CONTRACTORS.NEW }>
        <Button type="primary">
          + Add contractor
        </Button>
      </Link>
      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        className="search-input"
        onChange={ (e) => {
          handleChangeSearch(e);
          debouncedResults();
        } }
      />
    </div>
  )
}

export default ContractorsHeader;
