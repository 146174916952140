import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getCasesFail,
  getCasesRequest,
  getCasesSuccess, getCommentsFail, getCommentsRequest, getCommentsSuccess,
  getCurrentCaseFail,
  getCurrentCaseRequest,
  getCurrentCaseSuccess,
} from "./cases.actions";
import api from "services/api.service";

export const getCases = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  dispatch(getCasesRequest());
  try {
    const { filter, pagination, sort } = getState().cases;
    const response = await api.getCases(filter, pagination, sort);

    dispatch(getCasesSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getCasesFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getCurrentCase = (id: string, onError: () => void) => async (dispatch: TAppDispatch) => {
  dispatch(getCurrentCaseRequest());
  try {
    dispatch(getComments(+id))
    const response = await api.getCase(id);

    dispatch(getCurrentCaseSuccess(response.data));
  } catch (e: any) {
    dispatch(getCurrentCaseFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
    onError();
  }
}

export const getComments = (caseId: number) => async (dispatch: TAppDispatch) => {
  dispatch(getCommentsRequest());
  try {
    const response = await api.getCaseComments(caseId);

    dispatch(getCommentsSuccess(response.data));
  } catch (e: any) {
    dispatch(getCommentsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}
