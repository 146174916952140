import { IEquipment, IListEquipmentFilter } from "./listEquipment.types";
import { ISorting } from "services/interface.service";

export const ActionTypes = {
  GET_EQUIPMENTS_REQUEST: "[LIST EQUIPMENT] GET EQUIPMENTS REQUEST",
  GET_EQUIPMENTS_SUCCESS: "[LIST EQUIPMENT] GET EQUIPMENTS SUCCESS",
  GET_EQUIPMENTS_FAIL: "[LIST EQUIPMENT] GET EQUIPMENTS FAIL",
  UPDATE_EQUIPMENTS_FILTER: "[LIST EQUIPMENT] UPDATE EQUIPMENTS FILTER",
  UPDATE_EQUIPMENTS_PAGINATION: "[LIST EQUIPMENT] UPDATE EQUIPMENTS PAGINATION",
  UPDATE_EQUIPMENTS_SORT: "[LIST EQUIPMENT] UPDATE EQUIPMENTS SORT",
  GET_EXTRA_EQUIPMENTS_REQUEST: "[LIST EQUIPMENT] GET EXTRA EQUIPMENTS REQUEST",
  GET_EXTRA_EQUIPMENTS_SUCCESS: "[LIST EQUIPMENT] GET EXTRA EQUIPMENTS SUCCESS",
  GET_EXTRA_EQUIPMENTS_FAIL: "[LIST EQUIPMENT] GET EXTRA EQUIPMENTS FAIL",
} as const;

export const getEquipmentsRequest = () => ({
  type: ActionTypes.GET_EQUIPMENTS_REQUEST,
})

export const getEquipmentsSuccess = (equipments: IEquipment[], count: number) => ({
  type: ActionTypes.GET_EQUIPMENTS_SUCCESS,
  payload: { equipments, count },
})

export const getEquipmentsFail = () => ({
  type: ActionTypes.GET_EQUIPMENTS_FAIL,
})

export const updateEquipmentsFilter = (filter: IListEquipmentFilter) => ({
  type: ActionTypes.UPDATE_EQUIPMENTS_FILTER,
  payload: filter,
})

export const updateEquipmentsPagination = (page: number) => ({
  type: ActionTypes.UPDATE_EQUIPMENTS_PAGINATION,
  payload: { page },
})

export const updateEquipmentsSort = (sort: ISorting) => ({
  type: ActionTypes.UPDATE_EQUIPMENTS_SORT,
  payload: sort,
})

export const getExtraEquipmentsRequest = () => ({
  type: ActionTypes.GET_EXTRA_EQUIPMENTS_REQUEST,
})

export const getExtraEquipmentsSuccess = (equipments: IEquipment[], count: number) => ({
  type: ActionTypes.GET_EXTRA_EQUIPMENTS_SUCCESS,
  payload: { equipments, count },
})

export const getExtraEquipmentsFail = () => ({
  type: ActionTypes.GET_EXTRA_EQUIPMENTS_FAIL,
})
