import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getExtraProductsFail,
  getExtraProductsRequest,
  getExtraProductsSuccess,
  getProductsCategoriesFail,
  getProductsCategoriesRequest,
  getProductsCategoriesSuccess,
  getProductsFail,
  getProductsRequest,
  getProductsSuccess,
} from "./products.actions";
import api from "services/api.service";
import { IProductsState } from "./products.types";
import { extraLimit, extraPortion } from "services/data.service";

export const getProducts = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  dispatch(getProductsRequest());
  try {
    const { pagination, filter }: IProductsState = getState().products;

    const response = await api.getProducts(filter, extraPortion, (pagination.page - 1) * extraLimit);

    dispatch(getProductsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getProductsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getExtraProducts = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  const { filter, pagination, isExtraProductsLoading, products }: IProductsState = getState().products;
  const offset = (pagination.page - 1) * extraLimit + products.length;

  if (isExtraProductsLoading || products.length >= extraLimit || offset === pagination.count) {
    return;
  }

  dispatch(getExtraProductsRequest());

  try {
    const response = await api.getProducts(filter, extraPortion, offset);

    dispatch(getExtraProductsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getExtraProductsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getProductsCategories = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  const { categories }: IProductsState = getState().products;
  if (categories.length !== 0) {
    return;
  }

  dispatch(getProductsCategoriesRequest())
  try {
    const response = await api.getContractEquipmentCategories();

    dispatch(getProductsCategoriesSuccess(response.data.results));
  } catch (e: any) {
    dispatch(getProductsCategoriesFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}
