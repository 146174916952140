import { IClient, IUserManagementFilter } from "./userManagement.types";
import { ISorting } from "services/interface.service";

export const ActionTypes = {
  GET_CLIENTS_REQUEST: "[CLIENTS] GET CLIENTS REQUEST",
  GET_CLIENTS_SUCCESS: "[CLIENTS] GET CLIENTS SUCCESS",
  GET_CLIENTS_FAIL: "[CLIENTS] GET CLIENTS FAIL",
  UPDATE_CLIENTS_PAGINATION: "[CLIENTS] UPDATE CLIENTS PAGINATION",
  UPDATE_CLIENTS_FILTER: "[CLIENTS] UPDATE CLIENTS FILTER",
  UPDATE_CLIENTS_SORT: "[CLIENTS] UPDATE CLIENTS SORT",
  UPDATE_CLIENT: "[CLIENTS] UPDATE CLIENT",
  GET_NEW_CLIENTS_COUNT: "[CLIENTS] GET NEW CLIENTS COUNT",
} as const;

export const getClientsRequest = () => ({
  type: ActionTypes.GET_CLIENTS_REQUEST,
})

export const getClientsSuccess = (clients: IClient[], count: number) => ({
  type: ActionTypes.GET_CLIENTS_SUCCESS,
  payload: { clients, count },
})

export const getClientsFail = () => ({
  type: ActionTypes.GET_CLIENTS_FAIL,
})

export const updateClientsPagination = (page: number, rowsPerPage: number) => ({
  type: ActionTypes.UPDATE_CLIENTS_PAGINATION,
  payload: { page, rowsPerPage },
})

export const updateClientsSort = (sort: ISorting) => ({
  type: ActionTypes.UPDATE_CLIENTS_SORT,
  payload: sort,
})

export const updateClientsFilter = (filter: IUserManagementFilter) => ({
  type: ActionTypes.UPDATE_CLIENTS_FILTER,
  payload: filter,
})

export const updateClient = (client: IClient) => ({
  type: ActionTypes.UPDATE_CLIENT,
  payload: client,
})

export const getNewClientsCountSuccess = (count: number) => ({
  type: ActionTypes.GET_NEW_CLIENTS_COUNT,
  payload: count,
})
