import { FC, useState, useEffect } from "react";

import { Button, Input, message, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { Loading } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getProductsSelector } from "store/products/products.selector";
import { IProduct } from "store/products/products.types";
import api from "services/api.service";
import { getProducts, getExtraProducts } from "store/products/products.thunks";
import { updateProduct } from "store/products/products.actions";
import { extraLimit } from "services/data.service";

import "./ProductsTable.scss";

const ProductsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { products, isProductsLoading, categories, isExtraProductsLoading, pagination } = useAppSelector(getProductsSelector);

  const [ editingProduct, setEditingProduct ] = useState<IProduct | null>(null);
  const [ isEditingProductLoading, setIsEditingProductLoading ] = useState(false);
  const [ validateErrors, setValidateErrors ] = useState<string[]>([]);

  const handleSave = async () => {
    if (!editingProduct) {
      return;
    }

    const errors = [];

    if (editingProduct.category.id === 0) {
      errors.push("category");
    }

    if (editingProduct.product_number === "") {
      errors.push("product_number");
    }

    if (editingProduct.description === "") {
      errors.push("description")
    }

    if (errors.length > 0) {
      setValidateErrors(errors);
      return;
    } else {
      setValidateErrors([]);
    }

    try {
      setIsEditingProductLoading(true);
      if (editingProduct.id === 0) {
        await api.addProduct(editingProduct);
        dispatch(getProducts());
      } else {
        await api.editProduct(editingProduct);
        dispatch(updateProduct(editingProduct));
      }
      setIsEditingProductLoading(false);
      setEditingProduct(null);
      setValidateErrors([]);
    } catch (e: any) {
      setIsEditingProductLoading(false);
      setEditingProduct(null);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const columns: ColumnsType<IProduct> = [
    {
      title: "Product Number",
      dataIndex: "product_number",
      key: "product_number",
      render: (value, item) => item.product_number === "add_more"
        ? <Button type="text" onClick={ () => dispatch(getExtraProducts()) } disabled={ isExtraProductsLoading }>+ Show more</Button>
        :  item.id === editingProduct?.id ? (
          <Input
            className={ validateErrors.find((error) => error === "product_number") ? "error" : "" }
            value={ editingProduct.product_number }
            onChange={ (e) => setEditingProduct({ ...editingProduct, product_number: e.target.value }) }
          />
        ) : <p className="active">{ value }</p>,
      onHeaderCell: () => ({
        id: "products-header-cell",
      }),
      width: 200,
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 4 : 1,
        style: { textAlign: item.product_number === "add_more" ? "center" : "initial" },
      }),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, item) => item.product_number === "add_more" ? null : item.id === editingProduct?.id ? (
          <Select
            className={ validateErrors.find((error) => error === "category") ? "error" : "" }
            value={ editingProduct.category?.name?.toString() }
            options={ categories.map((item) => ({ value: item.id.toString(), label: item.name })) }
            onChange={ (value) => {setEditingProduct( { ...editingProduct, category: categories.find((item) => item.id == +value )! } ) } }
            style={ { width: "150px" } }
          />
        ) : item.category?.name ? <p className="active thin">{ item.category?.name }</p> : "-/-",
      width: 150,
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value, item) => item.product_number === "add_more" ? null : item.id === editingProduct?.id ? (
        <Input
          className={ validateErrors.find((error) => error === "description") ? "error" : "" }
          value={ editingProduct.description }
          onChange={ (e) => setEditingProduct({ ...editingProduct, description: e.target.value }) }
        />
      ) : value,
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (value, item) => item.product_number === "add_more" ? null : item.id === editingProduct?.id ? (
        <Input
          className={ validateErrors.find((error) => error === "vendor") ? "error" : "" }
          value={ editingProduct.vendor }
          onChange={ (e) => setEditingProduct({ ...editingProduct, vendor: e.target.value }) }
        />
      ) : value,
      width: 150,
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: editingProduct === null ? <Button onClick={ () => setEditingProduct({ id: 0, product_number: "", description: "", category: categories[ 0 ], vendor:  "" }) } type="primary">+ Add</Button> : "",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (value, item) => item.product_number === "add_more" ? null : (
        <div>
          { item.id === editingProduct?.id ? (
            <>
              <Button
                size="small"
                type="primary"
                onClick={ handleSave }
                disabled={ isEditingProductLoading }
              >
                <Loading isLoading={ isEditingProductLoading }>
                  Save
                </Loading>
              </Button>

              <Button
                size="small"
                disabled={ isEditingProductLoading }
                style={ { marginLeft: "10px" } }
                onClick={ () => {
                  setEditingProduct(null);
                  setValidateErrors([]);
                } }
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              size="small"
              onClick={ () => setEditingProduct(item) }
            >
              Edit
            </Button>
          ) }
        </div>
      ),
      width: 190,
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
  ]

  useEffect(() => {
    const table = document.getElementById("products-table");
    if (table) {
      const body: any = table.getElementsByClassName("ant-table-body");

      if (body.length > 0) {
        body[ 0 ].onscroll = (e: any) => {
          const tbody: any = table.getElementsByClassName("ant-table-tbody");
          if (tbody.length > 0) {
            if (tbody[ 0 ].offsetHeight === e.target.scrollTop + e.target.offsetHeight) {
              dispatch(getExtraProducts());
            }
          }
        };
      }
    }
  }, [ isProductsLoading ])

  return (
    <Loading isLoading={ isProductsLoading } isColored isFullWidth height={ 200 }>
      <Table
        className="cases-table products-table"
        id="products-table"
        columns={ columns }
        dataSource={ (editingProduct && editingProduct.id === 0) ? (products.length >= extraLimit || ((pagination.page - 1) * extraLimit + products.length) === pagination.count) ? [ editingProduct, ...products ] : [ editingProduct, ...products, { product_number: "add_more" } as unknown as IProduct ] : (products.length >= extraLimit || ((pagination.page - 1) * extraLimit + products.length) === pagination.count) ? products : [ ...products, { product_number: "add_more" } as unknown as IProduct ] }
        pagination={ false }
        rowKey={ (item) => item.id }
        scroll={ { y: window.innerHeight - 233 } }
        tableLayout="auto"
      />
    </Loading>
  )
}

export default ProductsTable;
