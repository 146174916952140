import { FC, useEffect, useState } from "react";

import { Input, message, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import { Loading } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { IShortContract } from "store/contracts/contracts.types";
import { isFuture } from "services/data.service";
import greenDotIcon from "data/icons/dot-green.svg";
import grayDotIcon from "data/icons/dot-gray.svg";
import warningDotIcon from "data/icons/warning-dot.svg";
import "components/cases/CasesTable/CasesTable.scss";
import { RouteLinks } from "services/router.service";
import editIcon from "data/icons/edit-light.svg";
import closeIcon from "data/icons/close.svg";
import checkIcon from "data/icons/check.svg";
import { updateAlias, updateContractsSort } from "store/contracts/contracts.actions";
import { getContracts } from "store/contracts/contracts.thunks";
import api from "services/api.service";

import "./ContractsTable.scss";

const ContractsTable: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isContractsLoading, contracts, sort } = useAppSelector(getContractsSelector);

  const [ updateState, setUpdateState ] = useState(false);
  useEffect(() => setUpdateState(!updateState), [ isContractsLoading ])

  const [ editAliasId, setEditAliasId ] = useState<number | null>(null);
  const [ editingAlias, setEditingAlias ] = useState("");
  const [ isAliasLoading, setIsAliasLoading ] = useState(false);

  const handleEditAlias = (item: IShortContract, e: any) => {
    e.stopPropagation();
    setEditAliasId(item.id);
    setEditingAlias(item.alias.alias);
  }

  const handleCompleteAlias = async (item: IShortContract, e: any) => {
    e.stopPropagation();
    try {
      setIsAliasLoading(true);
      await api.setContractAlias(editingAlias, item.id);

      dispatch(updateAlias(item, editingAlias));
      setIsAliasLoading(false);
      setEditingAlias("");
      setEditAliasId(null);
    } catch (e: any) {
      setIsAliasLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const handleCloseAlias = (e: any) => {
    e.stopPropagation();
    setEditingAlias("");
    setEditAliasId(null);
  }

  const columns: ColumnsType<IShortContract> = [
    {
      title: "Contract Number",
      dataIndex: "identifier",
      key: "identifier",
      render: (value, item) =>
        <p className={ item.is_active ? "active" : "" }>
          <Tooltip
            title={ item.warning_reason === null ? "" : item.warning_reason }
            overlayInnerStyle={ { whiteSpace: "nowrap" } }
            overlayStyle={ { maxWidth: "initial" } }
          >
            <img className={ `dot-prefix ${ item.warning_reason !== null && "warning" }` } src={ item.warning_reason === null ? isFuture(item.date_end) ? greenDotIcon : grayDotIcon : warningDotIcon } alt="" />
          </Tooltip>
          { value }
        </p>,
      sortOrder: sort.column === "identifier" ? sort.direction : undefined,
      sorter: sort.column === "identifier",
      showSorterTooltip: false,
      width: 180,
      onHeaderCell: () => ({
        id: "contracts-header-cell",
        onClick: () => {
          dispatch(updateContractsSort({
            column: "identifier",
            direction: sort.column === "identifier" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContracts())
        },
      }),
    },
    {
      title: "Contractor",
      dataIndex: "customer",
      key: "customer",
      sortOrder: sort.column === "customer" ? sort.direction : undefined,
      sorter: sort.column === "customer",
      showSorterTooltip: false,
      className: `description-table-cell`,
      render: (value) => (
        <Tooltip title={ value }>
          <p>{ value }</p>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractsSort({
            column: "customer",
            direction: sort.column === "customer" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContracts())
        },
      }),
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      width: 350,
      render: (value, item) => {
        return item.id === editAliasId ? (
          <Loading isLoading={ isAliasLoading } isColored>
            <Input
              className="alias-input"
              value={ editingAlias }
              onChange={ (e) => setEditingAlias(e.target.value) }
              suffix={ (
                <>
                  <img
                    onClick={ (e) => handleCompleteAlias(item, e) }
                    src={ checkIcon }
                    alt=""
                    width={ 14 }
                    height={ 14 }
                  />

                  <img
                    onClick={ handleCloseAlias }
                    src={ closeIcon }
                    alt=""
                  />
                </>
              ) }
              onClick={ (e) => e.stopPropagation() }
            />
          </Loading>
          ) : (
          <div className="alias-wrapper">
            { value?.alias === "" ? (
              <span style={ { display: "flex" } } className="add-title" onClick={ (e) => handleEditAlias(item, e) }>
                <img className="edit-button" style={ { margin: "0 10px 0 0" } } src={ editIcon } alt="" />
                Add
              </span>
              ) : (
              <>
                <p className={ `alias-value ${ item?.is_active ? "alias-active" : "" }` }>{ value?.alias }</p>
                <img onClick={ (e) => handleEditAlias(item, e) } className="edit-button" src={ editIcon } alt="" />
              </>
            ) }
          </div>
        )
      },
      sortOrder: sort.column === "alias" ? sort.direction : undefined,
      sorter: sort.column === "alias",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractsSort({
            column: "alias",
            direction: sort.column === "alias" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContracts())
        },
      }),
    },
    {
      title: "Status",
      dataIndex: "status_value",
      key: "status_value",
      width: 140,
      onCell: () => ({
        style: { minWidth: "140px" },
      }),
    },
    {
      title: "Start date",
      dataIndex: "date_begin",
      key: "date_begin",
      sortOrder: sort.column === "date_begin" ? sort.direction : undefined,
      sorter: sort.column === "date_begin",
      showSorterTooltip: false,
      width: 140,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractsSort({
            column: "date_begin",
            direction: sort.column === "date_begin" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContracts())
        },
      }),
      onCell: () => ({
        style: { minWidth: "140px" },
      }),
    },
    {
      title: "End date",
      dataIndex: "date_end",
      key: "date_end",
      width: 140,
      render: (value, item) => <p className={ item.warning_reason === null ? "" : "warning" }>{ value }</p>,
      sortOrder: sort.column === "date_end" ? sort.direction : undefined,
      sorter: sort.column === "date_end",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractsSort({
            column: "date_end",
            direction: sort.column === "date_end" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContracts())
        },
      }),
      onCell: () => ({
        style: { minWidth: "140px" },
      }),
    },
  ]

  const handleOpenContract = (contractId: number) => {
    navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + contractId)
  }

  return (
    <Loading isLoading={ isContractsLoading } isColored isFullWidth height={ 200 }>
      <Table
        className="cases-table contracts-table"
        columns={ columns }
        dataSource={ contracts }
        pagination={ false }
        rowKey={ (item) => item.id }
        onRow={ (record) => {
          return {
            onClick: () => handleOpenContract(record.id),
          }
        } }
        scroll={ { y: window.innerHeight - 183 - (document.getElementById("contracts-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
      />
    </Loading>
  )
}

export default ContractsTable;
