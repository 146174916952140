import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import api from "services/api.service";
import { Loading } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getInvitations } from "store/invitations/invitations.thunks";
import { getInvitationsSelector } from "store/invitations/invitations.selector";

import "./ContractInvitationsTable.scss";

const ContractInvitationsTable = () => {
  const dispatch = useAppDispatch();
  const { isInvitationsLoading, invitations, filter } = useAppSelector(getInvitationsSelector);
  
  const handleAccept = async (token: string) => {
    try {
     await api.contractInvitationAccept(token);

     dispatch(getInvitations());
    } catch (e) {
      message.error("error");
    }
  };

  const handleReject = async (token: string) => {
    try {
     await api.contractInvitationReject(token);

     dispatch(getInvitations());
    } catch (e) {
      message.error("error");
    }
  };

  const columnsNew: ColumnsType = [
    {
      title: "Contract Number",
      dataIndex: "contract_identifier",
      key: "contract_identifier",
      showSorterTooltip: false,
    },
    {
      title: "Organization name",
      dataIndex: "send_by_org",
      key: "send_by_org",
      showSorterTooltip: false,
    },
    {
      title: "",
      dataIndex: "accept",
      key: "accept",
      width: 100,
      render: (value, item) => (
        <Button
          size="small"
          type="primary"
          onClick={ () => handleAccept(item.token) }
          style={ { width: 100 } }
        >
          Accept
        </Button>
      ),
    },
    {
      title: filter.category === "new" ? "" : "Rejected by",
      dataIndex: "rejected_by",
      key: "rejected_by",
      width: 100,
      render: (value, item) => (
        <Button
          size="small"
          type="default"
          onClick={ () => handleReject(item.token) }
          style={ { width: 100 } }
        >
          Reject
        </Button>
      ),
    },
  ];

  const columnsRejected: ColumnsType = [
    {
      title: "Contract Number",
      dataIndex: "contract_identifier",
      key: "contract_identifier",
      showSorterTooltip: false,
    },
    {
      title: "Organization name",
      dataIndex: "send_by_org",
      key: "send_by_org",
      showSorterTooltip: false,
    },
    {
      title: "Rejected by",
      dataIndex: "rejected_by",
      key: "rejected_by",
      width: 250,
      render: (value) => (
        <div>
          {value?.first_name} {value?.last_name}
        </div>
      ),
    },
  ];

  return (
    <Loading
      isLoading={ isInvitationsLoading }
      isColored
      isFullWidth
      height={ 200 }
    >
      <Table
        className="invitations-table"
        columns={ filter.category === "new" ? columnsNew : columnsRejected }
        dataSource={ invitations }
        pagination={ false }
        rowKey={ (item) => item.token }
        scroll={ { y: window.innerHeight - 220 - (document.getElementById("contracts-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
      />
    </Loading>
  );
};

export default ContractInvitationsTable;
