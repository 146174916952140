import { Button, Modal } from "antd";

import api from "services/api.service";
import { useAppDispatch } from "store";
import { Loading } from "components/common";
import closeIcon from "data/icons/close-gray.svg";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";

interface IProps {
  id: number;
  isTerminateModal: boolean;
  setIsTerminateModal: (value: boolean) => void;
}

const ContractTerminateModal = ({ id, isTerminateModal, setIsTerminateModal }: IProps) => {
  const dispatch = useAppDispatch();

  const onTerminate = async () => {
    if (id) {
      setIsTerminateModal(false);
      await api.terminateContract(id);

      dispatch(getContract(id));
      dispatch(getContractEquipments(+id));
    }
  };
  
  return (
    <Modal
      open={ isTerminateModal }
      closeIcon={
        <img src={ closeIcon } alt="" onClick={ () => setIsTerminateModal(false) } />
      }
      footer={ null }
      centered
      className="close-case-modal"
      width={ 320 }
      onCancel={ () => setIsTerminateModal(false) }
    >
      <div className="close-case-content">
        <h4>Terminate contract?</h4>

        <div className="buttons-wrapper">
          <Button type="primary" onClick={ onTerminate }>
            <Loading isLoading={ false }>
              Yes
            </Loading>
          </Button>
          <Button onClick={ () => setIsTerminateModal(false) }>No</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContractTerminateModal;
