import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import { getDownloadsFail, getDownloadsRequest, getDownloadsSuccess } from "./downloads.actions";
import api from "services/api.service";

export const getDownloads = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  dispatch(getDownloadsRequest())
  try {
    const { filter, pagination } = getState().downloads;
    const response = await api.getDownloads(filter, pagination);

    dispatch(getDownloadsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getDownloadsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}
