import { FC, useEffect, useMemo } from "react";

import { Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

import { TableCardSwitch } from "components/common";
import searchIcon from "data/icons/search.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getDownloadsSelector } from "store/downloads/downloads.selector";
import { switchDataDisplayType, updateDownloadsFilter, updateDownloadsPagination } from "store/downloads/downloads.actions";
import { IDownloadsFilter } from "store/downloads/downloads.types";
import { getDownloads } from "store/downloads/downloads.thunks";
import { RouteLinks } from "services/router.service";
import { initialState } from "store/downloads/downloads.reducer";
import { getUserSelector } from "store/user/user.selector";

import "./DownloadsHeader.scss";

const fileTypeOptions = [
  {
    value: "all",
    label: "All Files",
  },
  {
    value: "txt",
    label: ".txt",
  },
]

const DownloadsHeader: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataDisplayType, filter, pagination } = useAppSelector(getDownloadsSelector);
  const { isAdmin } = useAppSelector(getUserSelector);

  useEffect(() => {
    return () => {
      dispatch(updateDownloadsFilter(initialState.filter));
      dispatch(updateDownloadsPagination(initialState.pagination));
      dispatch(switchDataDisplayType("table"))
    }
  }, [])

  const handleUpdateFilter = (filter: IDownloadsFilter) => {
    dispatch(updateDownloadsFilter(filter));
    dispatch(updateDownloadsPagination({ page: 1, rowsPerPage: pagination.rowsPerPage }));
  }

  const updateInfo = () => {
    dispatch(getDownloads());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  const handleSwitch = () => {
    dispatch(switchDataDisplayType(dataDisplayType === "table" ? "cards" : "table"))
    updateInfo();
  }

  return (
    <div className="downloads-header-wrapper">
      { isAdmin && (
        <Button
          type="primary"
          className="add-file-button"
          onClick={ () => navigate(RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE) }
        >
          Add File +
        </Button>
      ) }
      {/*<Select*/}
      {/*  value={ filter.file_type }*/}
      {/*  options={ fileTypeOptions }*/}
      {/*  onChange={ (value) => {*/}
      {/*    handleUpdateFilter({ search: filter.search, file_type: value });*/}
      {/*    debouncedResults();*/}
      {/*  } }*/}
      {/*  bordered={ false }*/}
      {/*/>*/}
      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ (e) => {
          handleUpdateFilter({ search: e.target.value, file_type: filter.file_type });
          debouncedResults();
        } }
        className="search-input"
      />
      <TableCardSwitch
        isOn={ dataDisplayType === "table" }
        handleSwitch={ handleSwitch }
      />
    </div>
  )
}

export default DownloadsHeader;
