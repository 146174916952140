import { Reducer } from "react";

import { IUserState, TUserAction } from "./user.types";
import { ActionTypes } from "./user.actions";

const initialState: IUserState = {
  isAuthorized: false,
  user: null,
  isUserLoading: false,
  isAuthorizationLoading: false,
  isAdmin: false,
  notifications: [],
  isNotificationsLoading: false,
  isMobile: false,
  organization: null,
  meta: {},
}

const userReducer: Reducer<IUserState, TUserAction> = (prevState = initialState, action: TUserAction) => {
  switch (action.type) {
    case ActionTypes.AUTHORIZE_USER_REQUEST:
      return {
        ...prevState,
        isAuthorizationLoading: true,
      }
    case ActionTypes.AUTHORIZE_USER_SUCCESS:
      return {
        ...prevState,
        isAuthorizationLoading: false,
        isAuthorized: true,
      }
    case ActionTypes.AUTHORIZE_USER_FAIL:
      return {
        ...prevState,
        isAuthorizationLoading: false,
      }
    case ActionTypes.LOGOUT_USER:
      return {
        ...prevState,
        user: null,
        isAuthorized: false,
      }
    case ActionTypes.SET_IS_ADMIN:
      return {
        ...prevState,
        isAdmin: action.payload,
      }
    case ActionTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...prevState,
        isNotificationsLoading: true,
      }
    case ActionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...prevState,
        isNotificationsLoading: false,
        notifications: action.payload,
      }
    case ActionTypes.GET_NOTIFICATIONS_FAIL:
      return {
        ...prevState,
        isNotificationsLoading: false,
      }
    case ActionTypes.SET_IS_MOBILE:
      return {
        ...prevState,
        isMobile: action.payload,
      }
    case ActionTypes.UPDATE_PHONE_NUMBER:
      return {
        ...prevState,
        user: prevState.user ? {
          ...prevState.user,
          phone_number: action.payload,
        } : prevState.user,
      }
    case ActionTypes.GET_USER_DATA_REQUEST:
      return {
        ...prevState,
        isUserLoading: true,
      }
    case ActionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...prevState,
        user: action.payload,
        isUserLoading: false,
      }
    case ActionTypes.GET_USER_DATA_FAIL:
      return {
        ...prevState,
        isUserLoading: false,
      }
    case ActionTypes.GET_ORGANIZATION_DATA_SUCCESS:
      return {
        ...prevState,
        organization: action.payload,
      }
    case ActionTypes.GET_META:
      return {
        ...prevState,
        meta: action.payload,
      }
    default:
      return prevState;
  }
}

export default userReducer;
