import { useEffect, useState } from "react";

import { Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { Loading } from "components/common";
import checkIcon from "data/icons/check.svg";
import errorIcon from "data/icons/error.svg";
import { IContractCreate } from "store/contracts/contracts.types";
import api from "services/api.service";

import "./ContractCreateTable.scss";

interface IProps {
  products: any[];
  setProducts: (products: (prevState: any) => any[]) => void;
}

const ContractCreateTable = ({ products, setProducts }: IProps) => {
  const [ categories, setCategories ] = useState<{ value: number, label: string }[]>([]);

  const chooseCategory = (value: string, index?: number) => {
    setProducts((prevState) => ([ ...prevState.map((el: any, i: number | undefined) => index === i ? { ...el, category: value } : el) ]));
  };

  const getCategories = async () => {
    const response = await api.getContractEquipmentCategories();
    setCategories(response.data.results.map((el) => ({ value: el.id, label: el.name })));
  };

  const columns: ColumnsType<IContractCreate> = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 40,
    },
    {
      title: "Product number",
      dataIndex: "product_number",
      key: "product_number",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 200,
      render: (value, item) => (
        <div className="contract-create-table-category">
          <Select
            value={ value }
            className="contract-create-table-category-select"
            placeholder="Choose category"
            options={ categories }
            onChange={ value => chooseCategory(value, item.index) }
          />

          <img
            alt=""
            className="contract-create-table-category-check"
            src={ value ? checkIcon : errorIcon }
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Loading isLoading={ false } isColored height={ 200 }>
      <Table
        className="contract-create-table"
        columns={ columns }
        dataSource={ products }
        rowKey={ item => item.product_number }
        pagination={ false }
        tableLayout="auto"
      />
    </Loading>
  );
};

export default ContractCreateTable;
