import { useState } from "react";

import { message } from "antd";

import api from "services/api.service";

export const useContractors = (limit = 20) => {
  const [ contractors, setContractors ] = useState<{ value: string, label: string }[]>([]);

  const getContractors = async (search: string) => {
    try {
      const response = await api.getContractors({ search }, limit, 0);

      setContractors([
        { value: "all", label: "All" },
        ...response.data.results.map((res) => ({
          value: res.id.toString(),
          label: res.name,
        })),
      ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  };

  return {
    contractors,
    getContractors,
  };
};
