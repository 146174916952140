import { useEffect, useState } from "react";

import { Button, Form, Input, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import mainLogo from "data/icons/hydra-logo-dark.svg";
import checkCircle from "data/icons/check-circle.svg";
import closeCircle from "data/icons/close-circle.svg";
import accountCreateSuccess from "data/images/account-create-success.svg";
import { IOrganizationRegister } from "store/contracts/contracts.types";
import { authorizeUserSuccess } from "store/user/user.actions";
import { getUserSelector } from "store/user/user.selector";
import { formatPhoneNumber } from "services/data.service";
import { useAppDispatch, useAppSelector } from "store";
import { RouteLinks } from "services/router.service";
import api from "services/api.service";

import "./InviteCompletePage.scss";

const InviteCompletePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthorized, isAuthorizationLoading } = useAppSelector(getUserSelector);

  const [ urlSearchParams ] = useSearchParams();
  const token = urlSearchParams.get("token") || "";

  const [ form ] = Form.useForm<IOrganizationRegister>();
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ info, setInfo ] = useState<{ inviter: string; contract: string }>();

  const handleSubmit = async (values: any) => {
    try {
      await api.organizationInvitationRegister(values, token);

      try {
        const response = await api.authorizeUser(values.email, values.password);

        localStorage.setItem("AUTH_TOKEN", response.data.access);
        // setAccessToken(response.data.access);

        setTimeout(async () => {
          await invitationInfo();

          setSuccess(true);
        }, 0);
      } catch (error) {
        console.log(error);
      }

    } catch (error) {
      // @ts-ignore
      message.error(error.response.data.detail || "Something went wrong!");
    }
  };

  const invitationCheck = async () => {
    try {
      await api.contractInvitationCheck(token);

      setError(false);
    } catch (e) {
      setError(true);
    }
  };
  const invitationInfo = async () => {
    try {
      const response = await api.contractInvitationInfo(token);

      if (response.data) {
        setInfo(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const acceptInvite = async () => {
    try {
      const response = await api.contractInvitationAccept(token);

      if (response) {
        dispatch(authorizeUserSuccess());
        navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + response.data.contract);
      }

    } catch (error) {
      // @ts-ignore
      message.error(error.response.data.detail || "Something went wrong!");
    }
  };

  useEffect(() => {
    invitationCheck();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      invitationInfo();
    }
  }, [ isAuthorized ]);

  if (isAuthorizationLoading) {
    return (
      <div className="invite-complete"/>
    )
  }

  if (success) {
    return (
      <div className="invite-complete">
        <div className="invite-complete-left invite-complete-left-success">
          <div className="invite-complete-left-title">
            Account created <br/> successfully
          </div>

          <img
            alt=""
            className="img"
            src={ accountCreateSuccess }
          />
        </div>

        <div className="invite-complete-right invite-complete-right-success">
          <div className="title">
            Invitation to join to contract
          </div>

          <div className="text">
            Organization <span className="text-accent">{info?.inviter}</span> invited
            your organization to join to contract <span className="text-accent">{info?.contract}</span> on Hydra Platform
          </div>

          <Button
            type="primary"
            onClick={ acceptInvite }
          >
            Accept Invitation
          </Button>

          <img
            alt=""
            className="logo"
            src={ mainLogo }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="invite-complete">
      {!isAuthorized && (
        <div className="invite-complete-left">
          <div className="invite-complete-left-wrapper">
            <div className={ `notify ${ error ? "notify-error" : "notify" }` }>
              <img
                alt=""
                src={ error ? closeCircle : checkCircle }
              />

              Your invite is { error ? "invalid" : "valid" }
            </div>

            <div className="form">
              <div className="form-title">
                Create organization account
              </div>

              <Form
                className="registration-form-wrapper"
                layout="vertical"
                onFinish={ handleSubmit }
                form={ form }
              >
                <Form.Item
                  label="Official Name"
                  name="org_official_name"
                  rules={ [ { required: true, message: "Please input organization official name!" } ] }
                >
                  <Input
                    disabled={ error }
                    placeholder="Add Official Name"
                  />
                </Form.Item>

                <Form.Item
                  label="Short Name"
                  name="org_name"
                  rules={ [ { required: true, message: "Please input organization short name!" } ] }
                >
                  <Input
                    disabled={ error }
                    placeholder="Add Short Name"
                  />
                </Form.Item>

                <div style={ { display: "flex" } }>
                  <Form.Item
                    label="Email"
                    name="org_default_email"
                    rules={ [
                      { required: true, message: "Please input organization email!" },
                      { type: "email", message: "Please input correct email!" },
                    ] }
                    style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
                  >
                    <Input
                      disabled={ error }
                      placeholder="yourname@mail.com"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Phone"
                    name="org_phone"
                    style={ { width: "calc(50% - 10px)", display: "inline-block" } }
                  >
                    <Input
                      disabled={ error }
                      onChange={ (e) => {
                        form.setFieldsValue({ org_phone: formatPhoneNumber(e.target.value) })
                      } }
                      placeholder="+375 (00) 000-00-00"
                    />
                  </Form.Item>
                </div>

                <div className="hr"/>

                <div className="form-subtitle">
                  User info
                </div>

                <div style={ { display: "flex" } }>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={ [ { required: true, message: "Please input user first name!" } ] }
                    style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
                  >
                    <Input
                      disabled={ error }
                      placeholder="Add First Name"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={ [ { required: true, message: "Please input user last name!" } ] }
                    style={ { width: "calc(50% - 10px)", display: "inline-block" } }
                  >
                    <Input
                      disabled={ error }
                      placeholder="Add Last Name"
                    />
                  </Form.Item>
                </div>

                <div style={ { display: "flex" } }>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={ [
                      { required: true, message: "Please input user email!" },
                      { type: "email", message: "Please input correct email!" },
                    ] }
                    style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
                  >
                    <Input
                      disabled={ error }
                      placeholder="yourname@mail.com"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={ [ { required: true, message: "Please input user password!" } ] }
                    style={ { width: "calc(50% - 10px)", display: "inline-block" } }
                  >
                    <Input.Password disabled={ error } />
                  </Form.Item>
                </div>

                <Button
                  disabled={ error }
                  className="form-button"
                  type="primary"
                  htmlType="submit"
                >
                  Create account
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}

      <div className="invite-complete-right">
        {isAuthorized && error && (
          <div className="notify-error notify-auth">
            <img
              alt=""
              src={ closeCircle }
            />

            Your invite is invalid
          </div>
        )}

        <div className="title">
          Invite to join to contract
        </div>

        {isAuthorized ? (
          <div className="text">
            Organization <span className="text-accent">{info?.inviter}</span> invited
            your organization to join to contract <span className="text-accent">{info?.contract}</span> on Hydra Platform
          </div>
        ) : (
          <div className="text">
            Organization <span className="text-accent">ООО “Официальное название компании”</span> invited
            your organization to join to contract <span className="text-accent">0000-00/АА</span> on Hydra Platform
          </div>
        )}

        <Button
          onClick={ acceptInvite }
          type="primary"
        >
          Accept invite
        </Button>

        {(!isAuthorized || error) && (
          <>
            <div className="blur"/>

            <img
              alt=""
              className="logo"
              src={ mainLogo }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InviteCompletePage;
