import { FC } from "react";

import { useAppSelector } from "store";
import { getDownloadsSelector } from "store/downloads/downloads.selector";
import { Loading } from "components/common";
import { DownloadCard } from "components/downloads";

import "./DownloadCards.scss";

const DownloadCards: FC = () => {
  const { downloads, isDownloadsLoading, pagination } = useAppSelector(getDownloadsSelector);

  return (
    <Loading isLoading={ isDownloadsLoading } isFullWidth height={ 200 } isColored>
      <div className="download-cards-wrapper">
        { downloads.filter((download, index) => index <= pagination.rowsPerPage).map((download, index) => (
          <DownloadCard key={ index } download={ download } />
        )) }
      </div>
    </Loading>
  )
}

export default DownloadCards;
