import { Severity, SupportType } from "store/cases/cases.types";
import { formatDateItem } from "./data.service";

export const getSeverityTitle = (severity: Severity): string => {
  switch (severity) {
    case Severity.CRITICAL:
      return "Critical";
    case Severity.MAJOR:
      return "Major";
    case Severity.NORMAL:
      return "Normal";
    case Severity.INFO:
      return "Info"
  }
}

export const getSupportTypeTitle = (supportType: SupportType, isActive: boolean, isPending: boolean): string => {
  if (isPending) {
    return "Pending"
  }

  if (!isActive) {
    return "Expired"
  }

  switch (supportType) {
    case SupportType.FULL_TIME:
      return "24x7";
    case SupportType.FIX_TIME:
      return "CTR";
    case SupportType.NEXT_BUSINESS_DAY:
      return "NBD";
    case SupportType.WARRANTY:
      return "Warranty";
  }
}

export const getFileSizeTitle = (size: number): string => {
  if (size === 0) {
    return "-/-";
  }

  if (size < 1000) {
    return "> 1 KB";
  }

  if (size < 1000000) {
    return (size/1000).toFixed(2) + " KB";
  }

  if (size < 1000000000) {
    return (size/1000000).toFixed(2) + " MB";
  }

  if (size < 1000000000000) {
    return (size/1000000000).toFixed(2) + " GB";
  }

  return (size/1000000000000).toFixed(2) + " TB";
}

export const formatDate = (date: string): string => {
  return date === "" || date === null ? "" : `${ formatDateItem(new Date(date).getDate()) }.${ formatDateItem(new Date(date).getMonth() + 1) }.${ new Date(date).getFullYear() }`
}
