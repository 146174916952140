import { Reducer } from "react";

import { IDownloadsState, TDownloadsAction } from "./downloads.types";
import { ActionTypes } from "./downloads.actions";

export const initialState: IDownloadsState = {
  downloads: [],
  isDownloadsLoading: false,
  dataDisplayType: "table",
  filter: {
    file_type: "all",
    search: "",
  },
  pagination: {
    page: 1,
    rowsPerPage: 20,
    count: 0,
  },
}

const downloadsReducer: Reducer<IDownloadsState, TDownloadsAction> = (prevState = initialState, action: TDownloadsAction) => {
  switch (action.type) {
    case ActionTypes.GET_DOWNLOADS_REQUEST:
      return {
        ...prevState,
        isDownloadsLoading: true,
      }
    case ActionTypes.GET_DOWNLOADS_SUCCESS:
      return {
        ...prevState,
        isDownloadsLoading: false,
        downloads: action.payload.downloads,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_DOWNLOADS_FAIL:
      return {
        ...prevState,
        isDownloadsLoading: false,
      }
    case ActionTypes.UPDATE_FILTER:
      return {
        ...prevState,
        filter: action.payload,
      }
    case ActionTypes.UPDATE_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case ActionTypes.SWITCH_DATA_DISPLAY_TYPE:
      return {
        ...prevState,
        dataDisplayType: action.payload,
        pagination: {
          ...prevState.pagination,
          rowsPerPage: action.payload === "table" ? 20 : 16,
        },
      }
    default:
      return prevState;
  }
}

export default downloadsReducer;
