import { FC } from "react";

import { Spin } from "antd";

import "./Loading.scss";

interface IProps {
  children?: any;
  isLoading: boolean;
  width?: number;
  height?: number;
  isColored?: boolean;
  isFullWidth?: boolean;
}

const Loading: FC<IProps> = ({
  children,
  isLoading,
  width,
  height,
  isColored = false,
  isFullWidth = false,
}) => {
  return isLoading
    ? <Spin className={ `loading ${ isColored ? "colored" : "" }` } style={ { width: isFullWidth ? "100%" : width ? width + "px" : "initial", height: height ? height + "px" : "initial" } } />
    : children

}

export default Loading;
