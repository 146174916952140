import { FC, useState } from "react";

import { Button, Form, Input, message } from "antd";
import { NavLink } from "react-router-dom";

import api from "services/api.service";
import { RouteLinks } from "services/router.service";
import { Loading } from "components/common";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";

import "./ResetPasswordPage.scss";

const ResetPasswordPage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);
  const [ submittedEmail, setSubmittedEmail ] = useState("");
  const [ error, setError ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);
  const [ form ] = Form.useForm();

  const handleSubmit = async (values: { email: string }) => {
    try {
      setIsLoading(true);
      await api.resetPassword(values.email);

      setIsLoading(false);
      setSubmittedEmail(values.email);
      setError("");
      form.setFieldsValue({ email: "" })
    } catch (e: any) {
      setError(e.response.data.error || "Something went wrong!")
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const handleResetSubmit = () => {
    setSubmittedEmail("");
  }

  return (
    <div className={ `reset-password-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <Form
        className="reset-password-form-wrapper"
        onFinish={ handleSubmit }
        form={ form }
      >
        <h3>Forgot password?</h3>
        { error && ( <div className="login-error">{ error }</div> ) }
        { submittedEmail === "" ? (
          <Form.Item
            name="email"
            rules={ [ { required: true, message: "Please input your email!" }, { type: "email", message: "Please input correct email!" } ] }
          >
            <Input
              placeholder="Enter your email *"
            />
          </Form.Item>
        ) : (
          <p className="submit-notification">Email with the link for password reset was sent to { submittedEmail }.</p>
        ) }
        <Form.Item className="mg-btn-0">
          { submittedEmail === "" ? (
            <Button className="big-btn" type="primary" htmlType="submit">
              <Loading isLoading={ isLoading } height={ 23 } width={ 118 }>
                Reset password
              </Loading>
            </Button>
          ) : (
            <Button type="link" onClick={ handleResetSubmit }>
              Input another email
            </Button>
          ) }
        </Form.Item>
      </Form>
      <p className="bottom-description">Back to <NavLink to={ RouteLinks.AUTH }>log in</NavLink> or navigate the <NavLink to={ RouteLinks.MAIN }>main page</NavLink>.</p>
    </div>
  )
}

export default ResetPasswordPage;
