import { FC, useEffect, useState } from "react";

import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { RouteLinks } from "services/router.service";
import { Loading } from "components/common";
import background1Icon from "data/icons/not-found-background-1.svg";
import background2Icon from "data/icons/not-found-background-2.svg";
import background3Icon from "data/icons/not-found-background-3.svg";
import fourIcon from "data/icons/not-found-4.svg";
import four2Icon from "data/icons/not-found-4-2.svg";
import tumbleweedIcon from "data/icons/not-found-tumbleweed.svg";

import "./NotFoundPage.scss";

const NotFoundPage: FC = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const { isAuthorized } = useAppSelector(getUserSelector);

  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    if (isAuthorized && loc.pathname === RouteLinks.SUPPORT) {
      navigate(RouteLinks.PROTECTED.CASES.MAIN);
    }

    setTimeout(() => setIsLoading(false), 500);
  }, [])

  const handleGoHome = () => {
    if (isAuthorized) {
      navigate(RouteLinks.PROTECTED.CASES.MAIN);
    } else {
      navigate(RouteLinks.MAIN);
    }
  }

  return (
    <Loading isLoading={ isLoading } isColored isFullWidth height={ 400 }>
      <div className="not-found-page-wrapper">
        <img style={ { position: "absolute", right: "15%", bottom: "170px" } } src={ four2Icon } alt="" />
        <img style={ { position: "absolute", left: 0, bottom: 0, width: "100%" } } src={ background1Icon } alt="" />
        <img style={ { position: "absolute", left: "15%", bottom: "70px" } } src={ fourIcon } alt="" />
        <img style={ { position: "absolute", left: 0, bottom: 0, width: "100%" } } src={ background2Icon } alt="" />
        <img className="tumbleweed" src={ tumbleweedIcon } alt="" />
        <img style={ { position: "absolute", left: 0, bottom: 0, width: "100%" } } src={ background3Icon } alt="" />
        <h3>Sorry, page not found</h3>
        <h1>404</h1>
        <Button type="primary" onClick={ handleGoHome }>Go home</Button>
      </div>
    </Loading>
  )
}

export default NotFoundPage;
