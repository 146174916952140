import { ChangeEvent, FC, useEffect, useState } from "react";

import { Input, message, Tabs, TabsProps } from "antd";

import searchIcon from "data/icons/search.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { updateContractEquipmentsSort, updateEquipmentsFilter, updateEquipmentsPagination } from "store/contracts/contracts.actions";
import { getContractEquipments } from "store/contracts/contracts.thunks";
import "components/contracts/ContractsHeader/ContractsHeader.scss";
import api from "services/api.service";
import { initialState } from "store/contracts/contracts.reducer";

const ContractEquipmentsFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { equipmentsFilter, equipmentsPagination } = useAppSelector(getContractsSelector);

  const [ tabs, setTabs ] = useState<TabsProps[ "items" ]>([]);

  useEffect(() => {
    const getTabs = async () => {
      try {
        const response = await api.getContractEquipmentCategories();
        const results = response.data.results.map((res) => ({
          key: res.id.toString(),
          label: res.name,
        }));

        setTabs([
          { key: "all", label: "All" },
          ...results.map((item) => ({
            key: item.key.toString(),
            label: item.label,
          })),
          { key: "null", label: "Undefined" },
        ])
      } catch (e: any) {
        message.error(e.response.data.error || e.error || "Something went wrong!");
      }
    }
    getTabs();

    return () => {
      dispatch(updateEquipmentsFilter(initialState.equipmentsFilter));
      dispatch(updateContractEquipmentsSort(initialState.equipmentsSort));
      dispatch(updateEquipmentsPagination({
        page: initialState.equipmentsPagination.page,
        rowsPerPage: initialState.equipmentsPagination.rowsPerPage,
      }));
    }
  }, [])

  const handleChangeType = (type: string) => {
    dispatch(updateEquipmentsFilter({
      ...equipmentsFilter,
      type: type,
    }))
    dispatch(updateEquipmentsPagination({ page: 1, rowsPerPage: equipmentsPagination.rowsPerPage }));
    dispatch(getContractEquipments());
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEquipmentsFilter({
      ...equipmentsFilter,
      search: e.target.value,
    }))
    dispatch(updateEquipmentsPagination({ page: 1, rowsPerPage: equipmentsPagination.rowsPerPage }));
    dispatch(getContractEquipments());
  }

  return (
    <div className="contracts-header-wrapper">
      <Tabs items={ tabs } activeKey={ equipmentsFilter.type } onChange={ handleChangeType } />
      <Input
        value={ equipmentsFilter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ handleChangeSearch }
        className="search-input"
      />
    </div>
  )
}

export default ContractEquipmentsFilter;
