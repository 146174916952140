import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getEquipmentsFail,
  getEquipmentsRequest,
  getEquipmentsSuccess,
  getExtraEquipmentsFail,
  getExtraEquipmentsRequest,
  getExtraEquipmentsSuccess,
} from "./listEquipment.actions";
import api from "services/api.service";
import { IListEquipmentState } from "./listEquipment.types";
import { extraLimit, extraPortion } from "services/data.service";

export const getEquipments = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  try {
    dispatch(getEquipmentsRequest())
    const { filter, pagination, sort }: IListEquipmentState = getState().listEquipment;

    const response = await api.getEquipments(filter, sort, extraPortion, (pagination.page - 1) * extraLimit);

    dispatch(getEquipmentsSuccess(response.data.results.map((eq) => ({
      id: eq.id,
      serial_number: eq.serial_number,
      customer: eq.contract.contractor.name,
      customer_email: eq.contract.contractor.default_email,
      customer_phone: eq.contract.contractor.phone_number,
      customer_official_name: eq.contract.contractor.official_name,
      sla_ci: eq.sla_ci,
      support_type: eq.sla_ci.marker,
      support_type_name: eq.sla_ci.name,
      date_begin: eq.date_begin,
      date_end: eq.date_end,
      product_number: eq.product.product_number,
      description: eq.product.description,
      is_active: eq.is_active,
      is_pending: eq.is_pending,
      contractId: eq.contract.id,
      has_account: eq.contract.contractor.has_account,
      has_verified_account: eq.contract.contractor.has_verified_account,
    })), response.data.count));
  } catch (e: any) {
    dispatch(getEquipmentsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getExtraEquipments = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  const { filter, pagination, sort, isExtraEquipmentsLoading, equipments }: IListEquipmentState = getState().listEquipment;
  const offset = (pagination.page - 1) * extraLimit + equipments.length;

  if (isExtraEquipmentsLoading || equipments.length >= extraLimit || offset === pagination.count) {
    return;
  }

  try {
    dispatch(getExtraEquipmentsRequest());

    const response = await api.getEquipments(filter, sort, extraPortion, offset);

    dispatch(getExtraEquipmentsSuccess(response.data.results.map((eq) => ({
      id: eq.id,
      serial_number: eq.serial_number,
      customer: eq.contract.contractor.name,
      customer_email: eq.contract.contractor.default_email,
      customer_phone: eq.contract.contractor.phone_number,
      customer_official_name: eq.contract.contractor.official_name,
      sla_ci: eq.sla_ci,
      support_type: eq.sla_ci.marker,
      support_type_name: eq.sla_ci.name,
      date_begin: eq.date_begin,
      date_end: eq.date_end,
      product_number: eq.product.product_number,
      description: eq.product.description,
      is_active: eq.is_active,
      is_pending: eq.is_pending,
      contractId: eq.contract.id,
      has_account: eq.contract.contractor.has_account,
      has_verified_account: eq.contract.contractor.has_verified_account,
    })), response.data.count));
  } catch (e: any) {
    message.error(e.response.data.error || e.error || "Something went wrong!");
    dispatch(getExtraEquipmentsFail());
  }
}
