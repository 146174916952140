import * as actions from "./cases.actions";
import { IPagination, ISorting } from "services/interface.service";
import { IContractorSource, ICustomerSource, IProductSource, ISlaCi } from "store/contracts/contracts.types";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";

export type TCasesAction = ReturnType<Omit<typeof actions, "ActionTypes"> extends { [key: string]: infer U } ? U : never>

export interface ICasesState {
  cases: ICase[];
  isCasesLoading: boolean;
  currentCase: ICase | null;
  isCurrentCaseLoading: boolean;
  filter: ICasesFilter;
  pagination: IPagination;
  sort: ISorting;
  comments: IComment[];
  isCommentsLoading: boolean;
  ext_issue_tracker: IExtIssueTracker | null;
}

export interface ICase {
  id: number;
  attachments: number[];
  author_email: string;
  author_name: string;
  author_phone_number: string;
  contact_email: string;
  contact_name: string;
  contact_phone_number: string;
  closed_at: string;
  created_at: string;
  created_by: string | null;
  custom_id: number;
  customer: ICustomerSource;
  email_cc: string[];
  serial_number: string;
  severity: Severity;
  status: "open" | "close";
  subject: string;
  text: string;
  equipment: IEquipmentSource | null;
  case_resolution: ICaseResolution | null;
  ext_issue_tracker: IExtIssueTracker | null;
  contractor: IContractorSource;
  sla_ci: ISlaCi;
  provider: IProvider;
  granted_close: boolean;
}

interface IProvider {
  default_email: string;
  id: number;
  name: string;
  official_name: string;
  phone_number?: any;
}

export interface ICaseResolution {
  id: number;
  text: string;
}

export interface IExtIssueTracker {
  link: string;
  source_logo: string;
}

export enum Severity {
  CRITICAL = 3,
  MAJOR = 2,
  NORMAL = 1,
  INFO = 0,
}

export interface ICasesFilter {
  search: string;
  date_from: string;
  date_to: string;
  client: string;
  severity: Severity | null;
  type: "all" | "open" | "close";
}

export interface ICaseActionsLog {
  count: number;
  next: any;
  previous: any;
  results: IActionsLog[];
}

export interface IActionsLog {
  id: number;
  at: string;
  text: string;
  case: number;
  actor: number;
}

export enum SupportType {
  NEXT_BUSINESS_DAY = "next_business_day",
  FIX_TIME = "fix_time",
  FULL_TIME = "full_time",
  WARRANTY = "warranty",
}

export interface ICaseEquipment {
  id: number;
  contract: {
    contractor: IContractorSource;
  };
  customer: ICustomerSource;
  date_begin: string;
  date_end: string;
  is_active: boolean;
  is_pending: boolean;
  serial_number: string;
  product: IProductSource;
  support_type: SupportType;
  sla_ci: ISlaCi;
}

export interface IComment {
  id: number;
  user: string;
  date: string;
  time: string;
  text: string;
  files: any[];
}
