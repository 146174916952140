import { FC } from "react";

import { Button, Layout } from "antd";
import { NavLink } from "react-router-dom";

import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import mainBgPrimary from "data/images/main-bg2-primary.svg";
import mainBgSecondary from "data/images/main-bg2-secondary.svg";
import mainLogo from "data/icons/hydra-logo-dark.svg";
import mainPromo from "data/images/main-promo.svg";
import { RouteLinks } from "services/router.service";
import loginIcon from "data/icons/login.svg";

import "./LandingPage2.scss";

const LandingPageV2: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);

  return (
    <Layout className={ `landing-v2-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <div className="header">
        <img src={ mainLogo } alt=""/>

        <NavLink
          className="login-button"
          to={ RouteLinks.AUTH }
        >
          <Button type="primary" style={ { background: "#0C5463", marginLeft: "auto" } }>
            <img src={ loginIcon } alt=""/>
            Log in
          </Button>
        </NavLink>
      </div>

      <img src={ mainBgPrimary } alt="" className="bg"/>
      <img src={ mainBgSecondary } alt="" className="bg"/>

      <div className="group">
        <Layout className="form-wrapper">
          <h1>Одно приложение,<br/> которое заменит всех.</h1>

          <div className="subtitle">
            Храните все в одном и том же месте — даже если ваша команда <br/> этого не делает.
          </div>

          <NavLink to={ RouteLinks.AUTH }>
            <Button
              type="primary"
              style={ {
                background: "#0C5463",
                width: "fit-content",
                marginTop: "50px",
              } }
            >
              <img alt=""/>
              Попробовать бесплатно
            </Button>
          </NavLink>
        </Layout>

        <img src={ mainPromo } alt="" className="promo"/>
      </div>
    </Layout>
  )
}

export default LandingPageV2;
