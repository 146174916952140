import { FC, useEffect, useState } from "react";

import { Table, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import { IDownload } from "store/downloads/downloads.types";
import { useAppSelector } from "store";
import { getDownloadsSelector } from "store/downloads/downloads.selector";
import { Loading } from "components/common";
import { FileIcon } from "components/downloads";
import { getFileSizeTitle } from "services/title.service";
import downloadIcon from "data/icons/downloads-white.svg";
import exportIcon from "data/icons/export.svg";
import { RouteLinks } from "services/router.service";
import { downloadFromLink } from "services/data.service";
import { getUserSelector } from "store/user/user.selector";

import "./DownloadsTable.scss";

const columns: ColumnsType<IDownload> = [
  {
    title: "",
    dataIndex: "icon",
    key: "icon",
    render: (value, record) => <FileIcon size="small" fileName={ record.title } fileType={ "link" } />,
    width: 33,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value, record) =>
      <div className="downloads-title">
        <p className="title">{ record.title }</p>
        <p className="file-name">{ record.description }</p>
      </div>,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 130,
  },
  {
    title: "Size",
    dataIndex: "source_size",
    key: "source_size",
    render: (value) => getFileSizeTitle(value),
    width: 130,
  },
  // {
  //   title: "Deadline",
  //   dataIndex: "deadline",
  //   key: "deadline",
  //   render: (value) => <p className="deadline"><img src={ clockIcon } alt="" />{ value }</p>,
  // },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (value, record) =>
      <Button
        className="download-button"
        type="primary"
        onClick={ (e) => {
          e.stopPropagation();
          downloadFromLink(record.link);
        } }
      >
        <img src={ record.link === "" ? downloadIcon : exportIcon } alt="" />
      </Button>,
    width: 34,
  },
]

const DownloadsTable: FC = () => {
  const navigate = useNavigate();
  const { downloads, isDownloadsLoading } = useAppSelector(getDownloadsSelector);
  const { isAdmin } = useAppSelector(getUserSelector);

  const [ updateState, setUpdateState ] = useState(false);
  useEffect(() => setUpdateState(!updateState), [ isDownloadsLoading ])

  const handleOpenFile = (fileId: number) => {
    navigate(RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE + "/" + fileId)
  }

  return (
    <Loading isLoading={ isDownloadsLoading } isFullWidth height={ 200 } isColored>
      <Table
        columns={ columns }
        showHeader={ false }
        dataSource={ downloads }
        className="downloads-table"
        pagination={ false }
        rowKey={ (record) => record.id }
        onRow={ (record) => {
          return {
            onClick: () => isAdmin && handleOpenFile(record.id),
          }
        } }
        scroll={ { y: window.innerHeight - 183 } }
        tableLayout="auto"
      />
    </Loading>
  )
}

export default DownloadsTable;
