import { FC, useState } from "react";

import { Button, message, Popover } from "antd";
import { Input } from "antd/lib";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import emailIcon from "data/icons/email.svg";
import phoneIcon from "data/icons/phone.svg";
import logOutButton from "data/icons/logout.svg";
import editIcon from "data/icons/edit.svg";
import closeIcon from "data/icons/close.svg";
import checkIcon from "data/icons/check.svg";
import { logoutUser, updatePhoneNumber } from "store/user/user.actions";
import { formatPhoneNumber } from "services/data.service";
import api from "services/api.service";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";

import "./UserMenu.scss";

const UserMenu: FC = () => {
  const { user, isMobile, isUserLoading } = useAppSelector(getUserSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [ phoneEditing, setPhoneEditing ] = useState<string | null>(null);
  const [ isPhoneLoading, setIsPhoneLoading ] = useState(false);

  const handleLogoutUser = () => {
    dispatch(logoutUser());
    localStorage.removeItem("AUTH_TOKEN");
    navigate(RouteLinks.MAIN);
  }

  const handleCompletePhone = async () => {
    if (phoneEditing) {
      try {
        setIsPhoneLoading(true);
        await api.editPhone(phoneEditing);
        dispatch(updatePhoneNumber(phoneEditing));
        setIsPhoneLoading(false);
        setPhoneEditing(null);
      } catch (e: any) {
        setIsPhoneLoading(false);
        message.error(e.response.data.error || e.error || "Something went wrong!");
      }
    }
  }

  const getPopoverContent = () => (
    <div className="user-menu-popover-wrapper">
      <div className="user-info">
        <div className="mock-avatar" />
        <p className="user-name">
          { user?.first_name } { user?.last_name }
        </p>
      </div>
      <div className="user-info">
        <img className="info-icon" src={ emailIcon } alt="" />
        <p className="info-title">{ user?.email }</p>
      </div>
      <div className="user-info">
        <img className="info-icon" src={ phoneIcon } alt="" />
        <p className="info-title">
          { phoneEditing !== null ? (
            <Input
              value={ phoneEditing }
              onChange={ (e) => setPhoneEditing(formatPhoneNumber(e.target.value)) }
              className="info-phone-input"
              suffix={
                <Loading isLoading={ isPhoneLoading } isColored>
                  <img className="input-click" width={ 14 } height={ 14 } src={ checkIcon } alt="" onClick={ handleCompletePhone } />
                  <img className="input-click" onClick={ () => setPhoneEditing(null) } src={ closeIcon } alt="" />
                </Loading>
              }
            />
          ) : (
            <>
              { user?.phone_number }
              <Button
                type="text"
                icon={ <img src={ editIcon } alt="" /> }
                onClick={ () => setPhoneEditing(user?.phone_number || "") }
              />
            </>
          ) }
        </p>
      </div>
      <div className="user-info clickable" onClick={ handleLogoutUser }>
        <img className="info-icon" src={ logOutButton } alt="" />
        <p className="info-title">Log out</p>
      </div>
    </div>
  )

  return (
    <div className="user-menu-wrapper">
      <Loading isLoading={ isUserLoading || !user } isColored width={ 24 }>
        <Popover overlayClassName={ `user-menu-popover ${ isMobile ? "mobile" : "" }` } content={ getPopoverContent } trigger="click">
          <div className="mock-avatar" />
          <p className="user-name">{ user?.first_name } { user?.last_name }</p>
        </Popover>
      </Loading>
    </div>
  )
}

export default UserMenu;
