import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getStorageSelector } from "store/storage/storage.selector";
import { updateStoragePagination } from "store/storage/storage.actions";
import { getStorage } from "store/storage/storage.thunks";
import { Loading } from "components/common";
import { extraLimit } from "services/data.service";

const StoragePagination: FC = () => {
  const dispatch = useAppDispatch();
  const { pagination, isExtraStorageLoading } = useAppSelector(getStorageSelector);

  const handleUpdatePagination = (page: number, pageSize: number) => {
    dispatch(updateStoragePagination(page, pageSize));
    dispatch(getStorage());
  }

  return (
    <div className="default-footer-wrapper">
      <Loading isLoading={ isExtraStorageLoading } isColored />
      <Pagination
        total={ pagination.count }
        showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
        pageSize={ extraLimit }
        current={ pagination.page }
        onChange={ handleUpdatePagination }
        showSizeChanger={ false }
      />
    </div>
  )
}

export default StoragePagination;
