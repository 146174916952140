import { SetStateAction, useEffect, useState } from "react";

import { Button, Input, message, Select } from "antd";
import { useNavigate } from "react-router-dom";

import lineGroup from "data/icons/line-group.svg";
import successCircleWhite from "data/icons/success-circle-white.svg";
import ContractCreateUpload from "components/contracts/ContractCreateUpload";
import ContractCreateTable from "components/contracts/ContractCreateTable";
import { IContractCreate } from "store/contracts/contracts.types";
import { useContractors } from "helpers/useContractors";
import { RouteLinks } from "services/router.service";
import backIcon from "data/icons/arrow-left.svg";
import api from "services/api.service";

import "./ContractTabs.scss";

const tabs = [
  {
    step: 1,
    text: "Contract type",
  },
  {
    step: 2,
    text: "Contractor info",
  },
  {
    step: 3,
    text: "Contract info",
  },
  {
    step: 4,
    text: "SLA info",
  },
]

const ContractTabs = () => {
  const navigate = useNavigate();
  const { contractors, getContractors } = useContractors(100);

  const getCategories = async () => {
    const response = await api.getContractCategories();
    setCategories(response.data.results.map((el) => ({ value: el.id, label: el.name })));
  };

  const [ activeTab, setActiveTab ] = useState( 0 );
  const [ contractor, setContractor ] = useState<any>(null);
  const [ contractName, setContractName ] = useState("");
  const [ fileId, setFileId ] = useState<number>();
  const [ companyType, setCompanyType ] = useState("sp");
  const [ categories, setCategories ] = useState<{ value: number, label: string }[]>([]);
  const [ isNewContract, setIsNewContract ] = useState(false);
  const [ contractCategory, setContractCategory ] = useState(undefined);
  const [ products, setProducts ] = useState<IContractCreate[]>([]);
  const [ isProductsStep, setIsProductsStep ] = useState(false);
  const [ slaDescription, setSlaDescription ] = useState<string>("");
  const [ isLoading, setIsLoading ] = useState(false);

  const onSlaChange = (e: { target: { value: SetStateAction<string | undefined>; }; }) => {
    setSlaDescription(e.target.value as string);
  }

  const onContactFileNextStep = async () => {
    try {
      await api.productsBulk(products);

      setIsProductsStep(false);
      setActiveTab(3);
    } catch (e) {
      message.error("Something went wrong!");
    }
  };

  const handleCreateContract = async () => {
    try {
      setIsLoading(true);

      const contractData = await api.initContract({
        contractor: contractor,
        identifier: contractName,
        me_type: companyType,
        contractor_type: "",
        sla: slaDescription,
        contract_category: contractCategory,
      });

      if (fileId && !isNewContract) {
        await api.deserializeFileProcess(fileId, contractData.data.id);
      }

      if (contractData.data.id) {
        navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + contractData.data.id);
      }
    } catch (e) {
      // @ts-ignore
      message.error(e?.response.data.detail || JSON.stringify(e?.response.data) );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getCategories();
    getContractors("");
  }, []);

  return (
    <div>
      <div className="contract-tabs">
        {tabs.map(({ text, step  }, i, arr) => (
          <div className="contract-tab" key={ i }>
            <div
              className={ `contract-tab-step ${
                step <= activeTab ? "contract-tab-step-active" : "" } ${
                step - 1 <= activeTab ? "contract-tab-step-complete" : "" 
              }` }
            >
              <div className={ `contract-tab-step-value ${ step - 1 <= activeTab ? "contract-tab-step-value-active" : "" }` }>
                <div className="contract-tab-step-value-ins">
                  {step <= activeTab ? <img src={ successCircleWhite } alt=""/> : step }
                </div>
              </div>

              <div className="contract-tab-step-text">
                { text }
              </div>
            </div>

            { i !== arr.length - 1 && (
              <div className="contract-tab-divider"/>
            )}
          </div>
        ))}
      </div>

      {activeTab === 0 && (
        <div className="contract-tab-area">
          <div className="contract-tab-area-title">Step 1</div>
          <div className="contract-tab-area-subtitle">Choose contract type</div>

          <div className="contract-tab-first">
            <img src={ lineGroup } alt="" className="contract-tab-first-img" />

            <div className="contract-tab-first-title">
              Line
            </div>

            <ul>
              <li>Customer</li>
              <li>Service provider</li>
            </ul>

            <div className="contract-tab-first-circle">
              <div className="contract-tab-first-circle-active"/>
            </div>
          </div>

          <Button
            type="primary"
            onClick={ () => setActiveTab( prevState => prevState + 1 ) }
            className="contract-tab-area-button"
          >
            Next Step
          </Button>
        </div>
      )}

      {activeTab === 1 && (
        <div className="contract-tab-area">
          <div className="contract-tab-area-title">Step 2</div>
          <div className="contract-tab-area-subtitle">Contractors info</div>

          <div className="contract-tab-second">
            <div className="contract-tab-second-label">
              My company:
            </div>

            <div className="radio">
              <div
                className="radio-option"
                onClick={ () => setCompanyType("sp") }
              >
                {companyType === "sp" ? (
                  <div className={ `radio-option-circle ${ companyType === "sp" ? "radio-option-circle-active" : "" }` }>
                    <div className="radio-option-circle-point"/>
                  </div>
                ) : (
                  <div className="radio-option-circle"/>
                )}

                <div>
                  Service provider
                </div>
              </div>

              <div
                className="radio-option"
                onClick={ () => setCompanyType("customer") }
              >
                {companyType === "customer" ? (
                  <div className={ `radio-option-circle ${ companyType === "customer" ? "radio-option-circle-active" : "" }` }>
                    <div className="radio-option-circle-point"/>
                  </div>
                ) : (
                  <div className="radio-option-circle"/>
                )}

                <div>
                  Customer
                </div>
              </div>
            </div>

            <div className="contract-tab-second-label">
              { companyType === "sp" ? "Customer" : "Service Provider"} (another company) *
            </div>

            <Select
              value={ contractor }
              options={ contractors }
              showSearch
              onSearch={ (value) => getContractors(value) }
              filterOption={ false }
              placeholder="Choose customer"
              notFoundContent={ null }
              onChange={ (value) => setContractor(value) }
              style={ { width: "500px", marginBottom: "127px" } }
            />

            <div className="contract-tab-area-group">
              {activeTab > 0 && (
                <Button
                  className="contract-tab-area-group-back-button"
                  type="link"
                  onClick={ () => setActiveTab( prevState => prevState - 1 ) }
                >
                  <img src={ backIcon } alt="" />
                  Back
                </Button>
              )}

              <Button
                type="primary"
                onClick={ () => setActiveTab( prevState => prevState + 1 ) }
                className={ activeTab <= 0 ? `contract-tab-area-button` : "" }
              >
                Next Step
              </Button>
            </div>
          </div>
        </div>
      )}

      {activeTab === 2 && !isProductsStep && (
        <div className="contract-tab-area">
          <div className="contract-tab-area-title">Step 3</div>
          <div className="contract-tab-area-subtitle">Contact info</div>

          <div className="contract-tab-third">
            <div style={ { flex: 1 } }>
              <div className="contract-tab-second-label">
                Contract identifier *
              </div>

              <Input
                placeholder="Add contract name"
                value={ contractName }
                onChange={ (e) => setContractName(e.target.value) }
              />

              <ContractCreateUpload
                setFileId={ setFileId }
                setProducts={ setProducts }
              />
            </div>

            <div className="contract-tab-third-divider">
              <div className="contract-tab-third-divider-line"/>

              <div>or</div>

              <div className="contract-tab-third-divider-line"/>
            </div>

            <div style={ { flex: 1 } }>
              <div className="contract-tab-second-label">
                Contract category *
              </div>

              <Select
                value={ contractCategory }
                className="contract-create-table-category-select"
                placeholder="Choose contract category"
                options={ categories }
                style={ { width: "100%" } }
                onChange={ value => setContractCategory(value) }
              />

              <div
                className={ `contract-tab-third-big-button ${
                  isNewContract ? "contract-tab-third-big-button-active" : "" } ${
                  fileId ? "contract-tab-third-big-button-disabled" : ""
                }` }
                onClick={ () => setIsNewContract(prevState => !prevState) }
              >
                <div>
                  <span className="contract-tab-third-big-button-accent">Create</span> a new contract <br/> on Hydra platform
                </div>
              </div>
            </div>
          </div>

          <div className="contract-tab-area-group">
            {activeTab > 0 && (
              <Button
                className="contract-tab-area-group-back-button"
                type="link"
                onClick={ () => setActiveTab( prevState => prevState - 1 ) }
              >
                <img src={ backIcon } alt="" />
                Back
              </Button>
            )}

            <Button
              type="primary"
              disabled={ !(fileId || isNewContract) }
              onClick={ () => {
                if (fileId && products.length && !isNewContract) {
                  setIsProductsStep(true);
                } else {
                  setIsProductsStep(false);
                  setActiveTab(prevState => prevState + 1);
                }
              } }
              className={ activeTab <= 0 ? `contract-tab-area-button` : "" }
            >
              Next Step
            </Button>
          </div>
        </div>
      )}

      {activeTab === 2 && isProductsStep && (
        <>
          <div className="contract-create-status">
            <div className="contract-create-status-description">
              There are <span className="contract-create-status-accent">{products.length} new products</span> in the
              contract without a category,<br/>
              all of them will fall into the <span className="contract-create-status-accent">«Other» category.</span>
            </div>
          </div>

          <ContractCreateTable
            products={ products }
            setProducts={ setProducts }
          />

          <div className="contract-tab-area-group" style={ { margin: "24px 130px" } }>
            {activeTab > 0 && (
              <Button
                className="contract-tab-area-group-back-button"
                type="link"
                onClick={ () => {
                  setProducts([]);
                  setIsProductsStep(false);
                } }
              >
                <img src={ backIcon } alt="" />
                Back
              </Button>
            )}

            <Button
              type="primary"
              onClick={ onContactFileNextStep }
              className={ activeTab <= 0 ? `contract-tab-area-button` : "" }
            >
              Next Step
            </Button>
          </div>
        </>
      )}

      {activeTab === 3 && (
        <div className="contract-tab-area">
          <div className="contract-tab-area-title">Step 4</div>
          <div className="contract-tab-area-subtitle">SLA info</div>
          <div className="contract-tab-second-label">
            SLA (Service Level Agreement) *
          </div>

          <Input.TextArea
            rows={ 11 }
            placeholder="Add SLA"
            onChange={ onSlaChange }
            value={ slaDescription }
          />

          <div className="contract-tab-area-group" style={ { marginTop: "30px" } }>
            {activeTab > 0 && (
              <Button
                className="contract-tab-area-group-back-button"
                type="link"
                onClick={ () => setActiveTab( prevState => prevState - 1 ) }
              >
                <img src={ backIcon } alt="" />
                Back
              </Button>
            )}

            <Button
              type="primary"
              disabled={ isLoading }
              onClick={ handleCreateContract }
              className={ activeTab <= 0 ? `contract-tab-area-button` : "" }
            >
              Create contract
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractTabs;
