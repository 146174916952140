import ContractCategories from "./ContractCategory/ContractCategory";
import ProductCategories from "./Categories/ProductCategories";
import SupportType from "./SupportType/SupportType";

import "./GeneralSettings.scss";

const GeneralSettings = () => {
  return (
    <>
      <div className="general-settings">
        <ProductCategories />
        <SupportType />
      </div>

      <div className="general-settings">
        <ContractCategories />
      </div>
    </>
  );
};

export default GeneralSettings;
