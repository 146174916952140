import { FC, useEffect, useMemo } from "react";

import { Col, DatePicker, Form, Input, Row, Select, TabsProps, Tabs } from "antd";
import debounce from "lodash.debounce";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getListEquipmentSelector } from "store/listEquipment/listEquipment.selector";
import searchIcon from "data/icons/search.svg";
import { IListEquipmentFilter } from "store/listEquipment/listEquipment.types";
import { updateEquipmentsFilter, updateEquipmentsPagination, updateEquipmentsSort } from "store/listEquipment/listEquipment.actions";
import { getEquipments } from "store/listEquipment/listEquipment.thunks";
import { initialState } from "store/listEquipment/listEquipment.reducer";
import { useContractors } from "helpers/useContractors";

import "./ListEquipmentFilter.scss";

interface IFilter extends IListEquipmentFilter {
  date: any[];
}

const tabs: TabsProps[ "items" ] = [
  {
    key: "active",
    label: "Active",
  },
  {
    key: "expired",
    label: "Expired",
  },
  {
    key: "pending",
    label: "Pending",
  },
  {
    key: "all",
    label: "All",
  },
]

const ListEquipmentFilter: FC = () => {
  const dispatch = useAppDispatch()
  const { isAdmin } = useAppSelector(getUserSelector);
  const { filter } = useAppSelector(getListEquipmentSelector);

  const { contractors, getContractors } = useContractors(100);

  useEffect(() => {
    isAdmin && getContractors("");

    return () => {
      dispatch(updateEquipmentsFilter(initialState.filter));
      dispatch(updateEquipmentsSort(initialState.sort));
      dispatch(updateEquipmentsPagination(initialState.pagination.page));
    }
  }, [])

  const handleChangeFilter = (_: Partial<IListEquipmentFilter>, values: IFilter) => {
    if (_.client && isAdmin) {
      getContractors("");
    }
    dispatch(updateEquipmentsFilter({
      ...values,
      date_begin: values.date && values.date[ 0 ] ? dayjs(values.date[ 0 ]).format("YYYY-MM-DD") : "",
      date_end: values.date && values.date[ 1 ] ? dayjs(values.date[ 1 ]).format("YYYY-MM-DD") : "",
    }));
    dispatch(updateEquipmentsPagination(1))
  }

  const handleChangeSubset = (subset: string) => {
    dispatch(updateEquipmentsFilter({
      ...filter,
      subset: subset as "active" | "expired" | "pending" | "all",
    }))
    dispatch(updateEquipmentsPagination(1))
    updateInfo();
  }

  const updateInfo = () => {
    dispatch(getEquipments());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="list-equipment-header-wrapper">
      <Form
        className="filter-wrapper"
        layout="vertical"
        initialValues={ filter }
        onValuesChange={ (e, values) => {
          handleChangeFilter(e, values);
          debouncedResults();
        } }
      >
        <Row gutter={ 24 }>
          <Col span={ 10 }>
            <Form.Item
              label="Search for Equipment"
              name="search"
            >
              <Input
                placeholder="Search"
                prefix={ <img src={ searchIcon } alt="" /> }
              />
            </Form.Item>
          </Col>
          <Col span={ 8 }>
            { isAdmin && (
              <Form.Item
                label="Contractor"
                name="client"
              >
                <Select
                  options={ contractors }
                  showSearch
                  onSearch={ (value) => getContractors(value) }
                  filterOption={ false }
                />
              </Form.Item>
            ) }
          </Col>
          <Col span={ 6 }>
            <Form.Item
              label="Date"
              name="date"
            >
              <DatePicker.RangePicker style={ { width: "100%" } } placeholder={ [ "Start Date", "End Date" ] } />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Tabs items={ tabs } activeKey={ filter.subset } onChange={ handleChangeSubset }  />
    </div>
  )
}

export default ListEquipmentFilter;
