import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Input } from "antd";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import searchIcon from "data/icons/search.svg";
import { getUserManagementSelector } from "store/userManagement/userManagement.selector";
import { updateClientsFilter, updateClientsPagination, updateClientsSort } from "store/userManagement/userManagement.actions";
import { getClients } from "store/userManagement/userManagement.thunks";
import { initialState } from "store/userManagement/userManagement.reducer";
import { CreateInviteModal } from "components/settings";

const TeamFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { filter, newClientsCount, pagination } = useAppSelector(getUserManagementSelector);

  useEffect(() => {
    return () => {
      dispatch(updateClientsFilter(initialState.filter));
      dispatch(updateClientsSort(initialState.sort));
      dispatch(updateClientsPagination(initialState.pagination.page, initialState.pagination.rowsPerPage));
    }
  }, [])

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateClientsFilter({
      ...filter,
      search: e.target.value,
    }))
    dispatch(updateClientsPagination(1, pagination.rowsPerPage));
  }

  const updateInfo = () => {
    dispatch(getClients());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="contracts-header-wrapper">
      <CreateInviteModal />

      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ (e) => {
          handleChangeSearch(e);
          debouncedResults();
        } }
        className="search-input"
      />
    </div>
  )
}

export default TeamFilter;
