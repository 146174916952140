import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Button, DatePicker, Form, Input, Menu, message, Popover, Select } from "antd";
import type { MenuProps } from "antd";

import { IContract } from "store/contracts/contracts.types";
import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { ContractInfoSlaModal, ContractTerminateModal, ContractPublishModal } from "components/contracts";
import { useContractors } from "helpers/useContractors";
import editBlue from "data/icons/edit-blue.svg";
import arrowUp from "data/icons/arrow-up.svg";
import dotsMenu from "data/icons/dots-menu.svg";
import closeRed from "data/icons/close-red.svg";
import eyeGray from "data/icons/eye-gray.svg";
import api from "services/api.service";

import "./ContractInfoForm.scss";

// eslint-disable-next-line @typescript-eslint/naming-convention
type MenuItem = Required<MenuProps>["items"][number];

interface IProps {
  contractEdit: boolean;
  setContractEdit: (value: boolean) => void;
  onSubmitEquipments: (contractId: number) => Promise<IEquipmentSource[]>;
}

const ContractInfoForm = ({ contractEdit, setContractEdit, onSubmitEquipments }: IProps) => {
  const dispatch = useAppDispatch();
  const [ form ] = Form.useForm();

  const { currentContract } = useAppSelector(getContractsSelector);
  const { contractors, getContractors } = useContractors();

  const [ isSlaModalOpen, setSlaModalOpen ] = useState<boolean>(false);
  const [ slaIdNew, setSlaIdNew ] = useState<number>();
  const [ isLoading, setIsLoading ] = useState(false);

  const [ isTerminateModal, setIsTerminateModal ] = useState(false);
  const [ inviteContractorModal, setInviteContractorModal ] = useState("");
  const [ isMenuVisible, setIsMenuVisible ] = useState(false);

  const initialValues = currentContract ? {
    end_user: currentContract.end_user,
    identifier: currentContract.identifier,
    contractor: currentContract?.customer ? {
      value: currentContract?.customer?.id, label: currentContract?.customer?.name } : null,
    date_of_signing: currentContract.date_of_signing ? dayjs(currentContract.date_of_signing) : null,
    date_begin: currentContract.date_begin ? dayjs(currentContract.date_begin) : null,
    date_end: currentContract.date_end ? dayjs(currentContract.date_end) : null,
    alias: currentContract.meta.alias,
    information: currentContract.meta.information,
  } : {};

  const handleCompleteForm = async (values: any) => {
    if (currentContract) {
      setIsLoading(true);

      const updatedContract = await api.updContract(currentContract.id, {
        ...values,
        date_of_signing: values.date_of_signing ? dayjs(values.date_of_signing).format("YYYY-MM-DD") : null,
        date_begin: values.date_begin ? dayjs(values.date_begin).format("YYYY-MM-DD") : null,
        date_end: values.date_end ? dayjs(values.date_end).format("YYYY-MM-DD") : null,
      });

      const updatedAlias = await api.setContractAlias(values.alias, currentContract.id);
      const updatedEquipments = await onSubmitEquipments(currentContract.id);

      dispatch(getContract(currentContract.id));
      dispatch(getContractEquipments(+currentContract.id));

      setContractEdit(false);
      setIsLoading(false);
    }
  };

  const handleExportPdf = async () => {
    try {
      if (currentContract) {
        api.contractsDownloadPdf(currentContract.id)
        .then((response) => {
          
          const url = window.URL.createObjectURL(new Blob([ response.data ]));
          const link = document.createElement("a");
          link.href = url;
          link.download = response.headers?.[ "content-disposition" ] || "downloaded-file";
          document.body.appendChild(link);
  
          link.click();
  
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
      }
    } catch (e) {
      message.error("Something went wrong!");
    }
  };

  const handleExportXlsx = async () => {
    try {
      if (currentContract) {
        api.contractsDownloadXlsx(currentContract.id)
        .then((response) => {
          
          const url = window.URL.createObjectURL(new Blob([ response.data ]));
          const link = document.createElement("a");
          link.href = url;
          link.download = response.headers?.[ "content-disposition" ] || "downloaded-file";
          document.body.appendChild(link);
  
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
      }
    } catch (e) {
      message.error("Something went wrong!");
    }
  };

  const getItems = ({ currentContract }: { currentContract: IContract }): MenuItem[] => ([
    {
      key: "invite-contractor",
      label: "Invite contractor",
      disabled: !currentContract.granted_invite,
      onClick: () => setInviteContractorModal(currentContract?.customer?.name),
    },
    {
      key: "export",
      label: "Export",
      children: [
        {
          key: "1",
          label: (
            <div
              style={ { display: "flex", justifyContent: "space-between" } }
              onClick={ handleExportPdf }
            >
              <span>PDF</span> <span>.pdf</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              style={ { display: "flex", justifyContent: "space-between" } }
              onClick={ handleExportXlsx }
            >
              <span>Excel</span> <span>.xls</span>
            </div>
          ),
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "terminate",
      label: "Terminate",
      itemIcon: (
        <img
          style={ { marginLeft: "85px", marginBottom: "-2px" } }
          src={ closeRed }
          alt=""
        />
      ),
      onClick: () => setIsTerminateModal(true),
    },
  ]);

  const onClick: MenuProps["onClick"] = (e) => {
    setIsMenuVisible(false);
  };

  useEffect(() => {
    getContractors("");
  }, []);

  useEffect(() => {
    if (currentContract) {
      form.setFieldsValue(initialValues);
    }
  }, [ currentContract ]);

  if (!contractEdit && currentContract) {
    return (
      <div className="contract-info-view-wrapper">
        <ContractTerminateModal
          id={ currentContract.id }
          isTerminateModal={ isTerminateModal }
          setIsTerminateModal={ setIsTerminateModal }
        />

        <ContractPublishModal
          id={ currentContract.id }
          name={ inviteContractorModal }
          isPublishModal={ !!inviteContractorModal }
          setIsPublishModal={ setInviteContractorModal }
        />

        <div className="contract-info-view">
          <div className="contract-info-view-item">
            <div className="contract-info-view-item-title">
              Contract number:
            </div>
            {currentContract.identifier}
          </div>

          <div className="contract-info-view-group">
            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Customer:
              </div>
              {currentContract?.customer?.name}
              {" "}
              {! currentContract.has_access_customer && (
                <span>
                  (Has no access.
                  {" "}
                  <span
                    className="link"
                    onClick={ () => setInviteContractorModal(currentContract?.customer?.name) }
                  >
                    Invite
                  </span>)
                </span>
              )}
            </div>

            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Contract date:
              </div>
              {currentContract.date_of_signing}
            </div>
          </div>

          <div className="contract-info-view-group">
            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Service provider:
              </div>
              {currentContract?.service_provider?.name}
              {" "}
              {! currentContract.has_access_sp && (
                <span>
                  (Has no access.
                  {" "}
                  <span
                    className="link"
                    onClick={ () => setInviteContractorModal(currentContract?.service_provider?.name) }
                  >
                    Invite
                  </span>)
                </span>
              )}
            </div>

            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Start date:
              </div>
              {currentContract.date_begin}
            </div>
          </div>

          <div className="contract-info-view-group">
            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                End user:
              </div>
              {currentContract.end_user}
            </div>

            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                End date:
              </div>
              {currentContract.date_end}
            </div>
          </div>

          <div className="contract-info-view-item">
            <div className="contract-info-view-item-title">
              Alias:
            </div>
            {currentContract.meta.alias}
          </div>

          <div className="contract-info-view-item">
            <div className="contract-info-view-item-title">
              Information:
            </div>
            {currentContract.meta.information}
          </div>

          <div className="contract-info-view-item">
            <Button
              className="sla-button"
              type="link"
              onClick={ () => setSlaModalOpen(true) }
            >
              Service Level Agreement
              <img src={ arrowUp } alt=""/>
            </Button>
          </div>

          {isSlaModalOpen && (
            <ContractInfoSlaModal
              contractId={ currentContract.id }
              slaId={ slaIdNew || currentContract.sla_pk }
              isSlaModalOpen={ isSlaModalOpen }
              setSlaModalOpen={ setSlaModalOpen }
              setSlaIdNew={ setSlaIdNew }
            />
          )}
        </div>

        {currentContract.granted_edit ? (
          <div className="contract-info-view-buttons">
            <Button
              className="contract-info-view-button"
              onClick={ () => setContractEdit(true) }
            >
              Edit contract
              <img src={ editBlue } alt=""/>
            </Button>

            <Popover
              placement="bottomRight"
              open={ isMenuVisible }
              overlayInnerStyle={ { padding: "0px" } }
              content={ (
                <Menu
                  onClick={ onClick }
                  style={ { width: 200 } }
                  mode="vertical"
                  selectable={ false }
                  items={ getItems({ currentContract }) }
                />
              ) }
              trigger="hover"
            >
              <Button
                className="contract-info-view-buttons-more"
                type="text"
                shape="circle"
                icon={ <img src={ dotsMenu } alt="" /> }
                onClick={ () => setIsMenuVisible(!isMenuVisible) }
              />
            </Popover>
          </div>
        ) : (
          <div className="view-only">
            <img src={ eyeGray } alt=""/>

            View only
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Form
        name="contract-info-form"
        className="contract-info-form"
        layout="horizontal"
        autoComplete="off"
        form={ form }
        onFinish={ handleCompleteForm }
        initialValues={ initialValues }
      >
        <div className="contract-info-form-wrapper">
          <div className="contract-info-form-form">
            <div style={ { display: "flex", width: "100%", gap: "20px" } }>
              <Form.Item
                label="Contract number"
                name="identifier"
                rules={ [ { required: true, message: "Please input contract number!" } ] }
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Contract date"
                name="date_of_signing"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <DatePicker style={ { width: "100%" } } />
              </Form.Item>
            </div>

            <div style={ { display: "flex", width: "100%", gap: "20px" } }>
              <Form.Item
                label="Contractor"
                name="contractor"
                rules={ [ { required: true, message: "Please select contractor!" } ] }
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <Select
                  options={ contractors }
                  showSearch
                  onSearch={ (value) => getContractors(value) }
                  filterOption={ false }
                  placeholder="Choose contractor"
                  notFoundContent={ null }
                />
              </Form.Item>

              <Form.Item
                label="Start Date"
                name="date_begin"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <DatePicker style={ { width: "100%" } } />
              </Form.Item>
            </div>

            <div style={ { display: "flex", width: "100%", gap: "20px" } }>
              <Form.Item
                label="End user"
                name="end_user"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="End Date"
                name="date_end"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <DatePicker style={ { width: "100%" } } />
              </Form.Item>
            </div>

            <Form.Item
              label="Alias"
              name="alias"
              style={ { marginBottom: "10px" } }
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Information"
              name="information"
            >
              <Input/>
            </Form.Item>
          </div>

          <div className="contract-info-form-buttons">
            <Button
              size="large"
              onClick={ () => setContractEdit(false) }
            >
              Cancel
            </Button>

            <Button
              disabled={ isLoading }
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ContractInfoForm;
