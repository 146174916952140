import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getListEquipmentSelector } from "store/listEquipment/listEquipment.selector";
import { updateEquipmentsPagination } from "store/listEquipment/listEquipment.actions";
import { getEquipments } from "store/listEquipment/listEquipment.thunks";
import { extraLimit } from "services/data.service";
import { Loading } from "components/common";

const ListEquipmentPagination: FC = () => {
  const dispatch = useAppDispatch();
  const { pagination, isExtraEquipmentsLoading } = useAppSelector(getListEquipmentSelector);

  const handleUpdatePagination = (page: number) => {
    dispatch(updateEquipmentsPagination(page));
    dispatch(getEquipments());
  }

  return (
    <div className="default-footer-wrapper">
      <Loading isLoading={ isExtraEquipmentsLoading } isColored />
      <Pagination
        total={ pagination.count }
        showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
        pageSize={ extraLimit }
        current={ pagination.page }
        onChange={ handleUpdatePagination }
        showSizeChanger={ false }
      />
    </div>
  )
}

export default ListEquipmentPagination;
