import { Button, Form, Input, Modal, message } from "antd";

import { Loading } from "components/common";
import closeIcon from "data/icons/close-gray.svg";
import emailIcon from "data/icons/email.svg";
import api from "services/api.service";

import "./GetInTouchModal.scss";

interface IProps {
  isGetInTouchOpen: boolean;
  setIsGetInTouchOpen: (value: boolean) => void;
}

const GetInTouchModal = ({ isGetInTouchOpen, setIsGetInTouchOpen }: IProps) => {
  const [ form ] = Form.useForm();

  const handleCompleteForm = async (values: any) => {
    try {
      await api.contactUs(values.email, values.message);

      message.success("Message sent!");
      setIsGetInTouchOpen(false);
    } catch (e) {
      message.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={ isGetInTouchOpen }
      closeIcon={
        <img
          alt=""
          src={ closeIcon }
          onClick={ () => setIsGetInTouchOpen(false) }
        />
      }
      footer={ null }
      centered
      className="contract-us-modal"
      width={ 500 }
      onCancel={ () => setIsGetInTouchOpen(false) }
    >
      <div className="contract-us-content">
        <h4>Get in touch</h4>

        <div>
        Have questions, feedback, or a proposal? Please fill out the form below, and our team will contact you back.
        </div>

        <Form
          className="invite-form-new"
          name="invite-form"
          layout="vertical"
          autoComplete="off"
          form={ form }
          onFinish={ handleCompleteForm }
        >
          <div className="contract-us-text">
            <Form.Item
              className="mg-btn-0"
              label="Email"
              name="email"
              rules={ [
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please input correct email!" },
              ] }
            >
              <Input
                placeholder="yourname@mail.com"
                prefix={ <img src={ emailIcon } alt=""/> }
              />
            </Form.Item>

            <Form.Item
              style={ { marginTop: "10px" } }
              className="mg-btn-0"
              label="Message"
              name="message"
            >
              <Input.TextArea
                placeholder="Leave us a message..."
              />
            </Form.Item>
          </div>

          <div className="buttons-wrapper">
            <Button
              type="primary"
              htmlType="submit"
              style={ { width: "100%" } }
            >
              <Loading isLoading={ false }>
                Send message
              </Loading>
            </Button>
          </div>

          {/*<div className="info">*/}
          {/*  Нажимая кнопку «Send message», вы подтверждаете, что ознакомлены и принимаете условия*/}
          {/*  {" "}*/}
          {/*  <span className="info-link">*/}
          {/*    Пользовательского соглашения*/}
          {/*  </span>*/}
          {/*</div>*/}
        </Form>
      </div>
    </Modal>
  );
};

export default GetInTouchModal;
