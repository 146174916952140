import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Input } from "antd";
import debounce from "lodash.debounce";

import searchIcon from "data/icons/search.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getStorageSelector } from "store/storage/storage.selector";
import { initialState } from "store/storage/storage.reducer";
import { updateStorageFilter, updateStoragePagination } from "store/storage/storage.actions";
import { getStorage } from "store/storage/storage.thunks";

import "./StorageHeader.scss";

const StorageHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { filter, pagination } = useAppSelector(getStorageSelector);

  useEffect(() => {
    return () => {
      dispatch(updateStorageFilter(initialState.filter));
      dispatch(updateStoragePagination(initialState.pagination.page, initialState.pagination.rowsPerPage));
    }
  }, [])

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateStorageFilter({
      ...filter,
      search: e.target.value,
    }))
    dispatch(updateStoragePagination(1, pagination.rowsPerPage));
  }

  const updateInfo = () => {
    dispatch(getStorage());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="storage-header-wrapper">
      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ (e) => {
          handleChangeSearch(e);
          debouncedResults()
        } }
        className="search-input"
      />
    </div>
  )
}

export default StorageHeader;
