import { FC, useCallback, useEffect, useState } from "react";

import { Button, Progress } from "antd";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import cloudIcon from "data/icons/cloud.svg";
import closeGrayIcon from "data/icons/close-gray.svg";
import downloadIcon from "data/icons/downloads-dark-blue.svg";
import { useLoading } from "helpers/useLoading";
import fileE from "data/icons/file-e.svg";
import api from "services/api.service";

import "./ContractCreateUpload.scss";

interface IProps {
  isLoading?: boolean;
  setFileId?: (id: number) => void;
  setProducts?: any;
}

const ContractCreateUpload: FC<IProps> = ({ isLoading, setFileId, setProducts }) => {
  const navigate = useNavigate();
  const [ files, setFiles ] = useState<File[]>([]);
  const [ errorMsg, setErrorMsg ] = useState("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles: File[]) => {
      let result: File[] = [];
      acceptedFiles && Object.keys(acceptedFiles).forEach((label) => {
        result.push(acceptedFiles![ +label ])
      })
      setFiles([ ...result ]);
    }, []),
    multiple: false,
  })

  const [ progressPercents, setProgressPercents ] = useState(30);

  const [ onFileUpload, loading ] = useLoading(async () => {
    try {
      const response = await api.uploadFile(files[ 0 ], "contract");

      if (response.id) {
        setFileId?.(response.id);

        const fileCheckResponse = await api.deserializeFileCheck(response.id);

        setProducts(fileCheckResponse.data.map((el: any, index: any) => ({ ...el, index })));
      } else {
        setErrorMsg("Upload failed");
      }
    } catch (e) {
      setErrorMsg("Upload failed");
    }
  });

  const normalizedFileSize = (size: number) => {
    return `${ (size / 1000).toFixed(2) } Kb`;
  };

  const onDelete = () => {
    setFiles([]);
    setFileId?.(0);
  };

  const onDownload = async () => {
    const response = await api.getContractTemplateFile();

    if (response.data) {
      const link = document.createElement("a");
      link.href = response.data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getProgressBar = () => {
    return (
      <>
        <p className="title-info">Loading...{ progressPercents }%</p>
        <Progress percent={ progressPercents } showInfo={ false } />
      </>
    )
  }

  const getSuccessMessage = () => {
    return (
      <>
        <p className="success-title">Success!</p>
        <p className="success-info">The file was uploaded successfully.</p>
        <p className="success-info">There are <b>10 new products</b> in the contract without a category, all of them will fall into the <b>«Other» category.</b></p>
      </>
    )
  }

  const getErrorMessage = () => {
    return (
      <div style={ { padding: "0 30px" } }>
        <p className="success-title">Error</p>
        <p className="success-info" style={ { marginBottom: "40px" } }>Sorry, file has failed to upload.</p>
        <Button className="create-button" type="primary">Try again</Button>
      </div>
    )
  }

  useEffect(() => {
    if (files.length !== 0) {
      onFileUpload();
    }
  }, [ files ]);

  return (
    <div className="create-contract-form-wrapper">
      { isLoading ? getErrorMessage() : (
        <>
          <p className="description">
            Upload completed Excel template here to automatically add new contract
          </p>

          <Button
            className="template-button"
            type="text"
            onClick={ onDownload }
          >
            Download template
            <img
              alt=""
              height="14"
              width="14"
              src={ downloadIcon }
            />
          </Button>

          {files.length === 0 ? (
            <div { ...getRootProps({ className: "dropzone" }) }>
              <input { ...getInputProps() } />

              <img src={ cloudIcon } alt=""/>

              <p>Click or drag completed template to this area to upload</p>
            </div>
          ) : (
            <div className="file">
              <img
                alt=""
                className="file-icon"
                src={ fileE }
              />

              <div className="file-info">
                <div className="file-info-title">
                  {files[ 0 ].name}
                </div>

                <div className="file-info-size">
                  {normalizedFileSize(files[ 0 ].size)}
                </div>

                {loading ? (
                  <div className="file-info-status">
                    loading...
                  </div>
                ) : (
                  <div className={ `file-info-status${ errorMsg && "-error" }` }>
                    {errorMsg || "Upload success"}
                  </div>
                )}
              </div>

              <img
                alt=""
                className="file-remove"
                onClick={ onDelete }
                src={ closeGrayIcon }
              />
            </div>
          )}

          {/*<div className="divider-wrapper">*/}
          {/*  <div/>*/}
          {/*  <p>or</p>*/}
          {/*  <div/>*/}
          {/*</div>*/}

          {/*<p className="footer-info">*/}
          {/*  <Button*/}
          {/*    type="link"*/}
          {/*    className="create-button"*/}
          {/*  >*/}
          {/*    Create*/}
          {/*  </Button>*/}

          {/*  <span>a new contract on Hydra platform</span>*/}
          {/*</p>*/}
        </>
      )}
    </div>
  )
}

export default ContractCreateUpload;
