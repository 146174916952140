import { FC } from "react";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import mobileOopsIcon from "data/icons/mobile-oops.svg";
import { RouteLinks } from "services/router.service";

import "./MobileOopsPage.scss";

const MobileOopsPage: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="default-page-wrapper mobile-oops-page-wrapper">
      <img src={ mobileOopsIcon } alt="" />
      <p>This page is only available on desktop</p>
      <Button type="primary" onClick={ () => navigate(RouteLinks.PROTECTED.CASES.MAIN) }>Go back to SDP Platform</Button>
    </div>
  )
}

export default MobileOopsPage;
