import { FC } from "react";

import fileIcon from "data/icons/file.svg";
import linkIcon from "data/icons/link.svg";

import "./FileIcon.scss";

interface IProps {
  size: "small" | "large";
  fileType: "file" | "link";
  fileName: string;
}

const FileIcon: FC<IProps> = ({ size, fileType, fileName }) => {
  return (
    <div className={ `file-icon-wrapper ${ size }` }>
      <img className="background" src={ fileIcon } alt="" />
      { fileType === "link" ? (
        <img className="link" src={ linkIcon } alt="" />
      ) : (
        <p className="title">{ fileName[ fileName.lastIndexOf(".") + 1 ] }</p>
      ) }
    </div>
  )
}

export default FileIcon;
