import { FC, useState } from "react";

import { Button, Modal } from "antd";

import { CaseForm } from "components/common";

import "./CreateCaseModal.scss";

interface IProps {
  serial_number?: string;
  id?: number;
  size?: "small" | "middle" | "large" | undefined;
}

const CreateCaseModal: FC<IProps> = ({ serial_number = "", id, size = "middle" }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <Button
        className="create-case-button"
        type="primary"
        size={ size }
        style={ { marginLeft: "auto" } }
        onClick={ () => setIsOpen(true) }
      >
        Open case
      </Button>

      <Modal
        className="create-case-form"
        open={ isOpen }
        onCancel={ () => setIsOpen(false) }
        footer={ null }
      >
        { isOpen && (
          <CaseForm
            afterEvent={ () => setIsOpen(false) }
            id={ id }
            serial_number={ serial_number }
          />
        ) }
      </Modal>
    </>
  )
}

export default CreateCaseModal;
