import { Reducer } from "react";

import { IContractorsState, TContractorsAction } from "./contractors.types";
import { ActionTypes } from "./contractors.actions";
import { extraPortion } from "services/data.service";

export const initialState: IContractorsState = {
  contractors: [],
  isContractorsLoading: false,
  isExtraContractorsLoading: false,
  pagination: {
    page: 1,
    rowsPerPage: extraPortion,
    count: 0,
  },
  filter: {
    search: "",
  },
  currentContractor: null,
  isCurrentContractorLoading: false,
}

const contractorsReducer: Reducer<IContractorsState, TContractorsAction> = (prevState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTRACTORS_REQUEST:
      return {
        ...prevState,
        isContractorsLoading: true,
      }
    case ActionTypes.GET_CONTRACTORS_SUCCESS:
      return {
        ...prevState,
        isContractorsLoading: false,
        contractors: action.payload.contractors,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_CONTRACTORS_FAIL:
      return {
        ...prevState,
        isContractorsLoading: false,
      }
    case ActionTypes.UPDATE_CONTRACTORS_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: action.payload.page,
        },
      }
    case ActionTypes.UPDATE_CONTRACTORS_FILTER:
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          ...action.payload,
        },
      }
    case ActionTypes.GET_CURRENT_CONTRACTOR_REQUEST:
      return {
        ...prevState,
        isCurrentContractorLoading: true,
      }
    case ActionTypes.GET_CURRENT_CONTRACTOR_SUCCESS:
      return {
        ...prevState,
        isCurrentContractorLoading: false,
        currentContractor: action.payload,
      }
    case ActionTypes.GET_CURRENT_CONTRACTOR_FAIL:
      return {
        ...prevState,
        isCurrentContractorLoading: false,
      }
    default:
      return prevState;
  }
}

export default contractorsReducer;
