import { Reducer } from "react";

import { TStorageActions, IStorageState } from "./storage.types";
import { ActionTypes } from "./storage.actions";
import { extraPortion } from "services/data.service";

export const initialState: IStorageState = {
  storage: [],
  isStorageLoading: false,
  isExtraStorageLoading: false,
  filter: {
    search: "",
  },
  pagination: {
    count: 0,
    page: 1,
    rowsPerPage: extraPortion,
  },
}

const storageReducer: Reducer<IStorageState, TStorageActions> = (prevState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_STORAGE_REQUEST:
      return {
        ...prevState,
        isStorageLoading: true,
      }
    case ActionTypes.GET_STORAGE_SUCCESS:
      return {
        ...prevState,
        isStorageLoading: false,
        storage: action.payload.storage,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_STORAGE_FAIL:
      return {
        ...prevState,
        isStorageLoading: false,
      }
    case ActionTypes.UPDATE_STORAGE_FILTER:
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          ...action.payload,
        },
      }
    case ActionTypes.UPDATE_STORAGE_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          ...action.payload,
        },
      }
    case ActionTypes.GET_EXTRA_STORAGE_REQUEST:
      return {
        ...prevState,
        isExtraStorageLoading: true,
      }
    case ActionTypes.GET_EXTRA_STORAGE_SUCCESS:
      return {
        ...prevState,
        isExtraStorageLoading: false,
        storage: [
          ...prevState.storage,
          ...action.payload.storage,
        ],
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
          rowsPerPage: prevState.pagination.rowsPerPage + extraPortion,
        },
      }
    default:
      return prevState;
  }
}

export default storageReducer;
