import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getInvitationsFail,
  getInvitationsRequest,
  getInvitationsSuccess,
  getExtraInvitationsFail,
  getExtraInvitationsRequest,
  getExtraInvitationsSuccess,
} from "./invitations.actions";
import api from "services/api.service";
import { IListInvitationState } from "./invitations.types";
import { extraLimit, extraPortion } from "services/data.service";

export const getInvitations = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  try {
    dispatch(getInvitationsRequest())
    const { filter, pagination, sort }: IListInvitationState = getState().invitations;

    const response = await api.getContractsInvitations(filter, extraPortion, (pagination.page - 1) * extraLimit);

    dispatch(getInvitationsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getInvitationsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getExtraInvitations = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  const { filter, pagination, sort, isExtraInvitationsLoading, invitations }: IListInvitationState = getState().invitations;
  const offset = (pagination.page - 1) * extraLimit + invitations.length;

  if (isExtraInvitationsLoading || invitations.length >= extraLimit || offset === pagination.count) {
    return;
  }

  try {
    dispatch(getExtraInvitationsRequest());

    const response = await api.getContractsInvitations(filter, extraPortion, offset);

    dispatch(getExtraInvitationsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    message.error(e.response.data.error || e.error || "Something went wrong!");
    dispatch(getExtraInvitationsFail());
  }
}
