import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getContractorsSelector } from "store/contractors/contractors.selector";
import { updateContractorsPagination } from "store/contractors/contractors.actions";
import { getContractors } from "store/contractors/contractors.thunks";
import { extraPortion } from "services/data.service";
import { Loading } from "components/common";

const ContractorsFooter: FC = () => {
  const dispatch = useAppDispatch();
  const { pagination, isExtraContractorsLoading } = useAppSelector(getContractorsSelector);

  const handleUpdatePagination = (page: number) => {
    dispatch(updateContractorsPagination(page));
    dispatch(getContractors());
  }

  return (
    <div className="default-footer-wrapper">
      <Loading isLoading={ isExtraContractorsLoading } isColored />
      <Pagination
        total={ pagination.count }
        showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
        pageSize={ extraPortion }
        current={ pagination.page }
        onChange={ handleUpdatePagination }
        showSizeChanger={ false }
      />
    </div>
  )
}

export default ContractorsFooter;
