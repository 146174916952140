import { FC, useState } from "react";

import { Button, Form, Input, message } from "antd";

import emailIcon from "data/icons/email.svg";
import { Loading } from "components/common";
import api from "services/api.service";

import "./InviteForm.scss";

const initialValues: { email: string } = {
  email: "",
};

interface IProps {
  afterEvent: () => void;
}

const InviteForm: FC<IProps> = ({ afterEvent }) => {
  const [ form ] = Form.useForm();
  const [ isLoading, setIsLoading ] = useState(false);

  const handleCompleteForm = async (values: { email: string }) => {
    try {
      setIsLoading(true);

      const response = await api.usersInvite(values.email);
      const result = response.data.detail;

      if (result.includes("invited")) {
        message.success(response.data.detail);
        afterEvent();
      } else {
        message.error(response.data.detail);
      }

      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  return (
    <Form
      className="invite-form-new"
      name="invite-form"
      layout="vertical"
      autoComplete="off"
      form={ form }
      onFinish={ handleCompleteForm }
      initialValues={ initialValues }
    >
      <h3>Invite user by email</h3>

      <Form.Item
        className="mg-btn-0"
        label="Email"
        name="email"
        rules={ [
          { required: true, message: "Please input your email!" },
          { type: "email", message: "Please input correct email!" },
        ] }
      >
        <Input
          placeholder="yourname@mail.com"
          prefix={ <img src={ emailIcon } alt="" /> }
        />
      </Form.Item>

      <Form.Item
        className="mg-btn-0"
        style={ { marginTop: "30px", marginBottom: "5px" } }
      >
        <Button
          className="big-btn"
          type="primary"
          htmlType="submit"
          disabled={ isLoading }
        >
          <Loading
            isLoading={ isLoading }
            width={ 81 }
            height={ 25 }
          >
            Send invite
          </Loading>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default InviteForm;
