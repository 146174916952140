import { Reducer } from "react";

import { IListEquipmentState, TListEquipmentAction } from "./listEquipment.types";
import { ActionTypes } from "./listEquipment.actions";
import { extraPortion } from "services/data.service";

export const initialState: IListEquipmentState = {
  filter: {
    search: "",
    date_begin: "",
    date_end: "",
    client: "all",
    subset: "active",
  },
  pagination: {
    page: 1,
    rowsPerPage: extraPortion,
    count: 0,
  },
  equipments: [],
  isEquipmentsLoading: false,
  isExtraEquipmentsLoading: false,
  sort: {
    column: "product_number",
    direction: "ascend",
  },
}

const listEquipmentReducer: Reducer<IListEquipmentState, TListEquipmentAction>
  = (prevState = initialState, action: TListEquipmentAction) => {
  switch (action.type) {
    case ActionTypes.GET_EQUIPMENTS_REQUEST: {
      return {
        ...prevState,
        isEquipmentsLoading: true,
      }
    }
    case ActionTypes.GET_EQUIPMENTS_SUCCESS: {
      return {
        ...prevState,
        isEquipmentsLoading: false,
        equipments: action.payload.equipments,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    }
    case ActionTypes.GET_EQUIPMENTS_FAIL: {
      return {
        ...prevState,
        isEquipmentsLoading: false,
      }
    }
    case ActionTypes.UPDATE_EQUIPMENTS_FILTER: {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          ...action.payload,
        },
      }
    }
    case ActionTypes.UPDATE_EQUIPMENTS_PAGINATION: {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          ...action.payload,
        },
      }
    }
    case ActionTypes.UPDATE_EQUIPMENTS_SORT:
      return {
        ...prevState,
        sort: action.payload,
      }
    case ActionTypes.GET_EXTRA_EQUIPMENTS_REQUEST:
      return {
        ...prevState,
        isExtraEquipmentsLoading: true,
      }
    case ActionTypes.GET_EXTRA_EQUIPMENTS_SUCCESS:
      return {
        ...prevState,
        isExtraEquipmentsLoading: false,
        equipments: [
          ...prevState.equipments,
          ...action.payload.equipments,
        ],
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
          rowsPerPage: prevState.pagination.rowsPerPage + extraPortion,
        },
      }
    case ActionTypes.GET_EXTRA_EQUIPMENTS_FAIL:
      return {
        ...prevState,
        isExtraEquipmentsLoading: false,
      }
    default:
      return prevState;
  }
}

export default listEquipmentReducer;
