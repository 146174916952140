import { IContractor, IContractorsFilter } from "./contractors.types";

export const ActionTypes = {
  GET_CONTRACTORS_REQUEST: "[CONTRACTORS] GET CONTRACTORS REQUEST",
  GET_CONTRACTORS_SUCCESS: "[CONTRACTORS] GET CONTRACTORS SUCCESS",
  GET_CONTRACTORS_FAIL: "[CONTRACTORS] GET CONTRACTORS FAIL",
  UPDATE_CONTRACTORS_PAGINATION: "[CONTRACTORS] UPDATE CONTRACTORS PAGINATION",
  UPDATE_CONTRACTORS_FILTER: "[CONTRACTORS] UPDATE CONTRACTORS FILTER",
  GET_CURRENT_CONTRACTOR_REQUEST: "[CONTRACTORS] GET CURRENT CONTRACTOR REQUEST",
  GET_CURRENT_CONTRACTOR_SUCCESS: "[CONTRACTORS] GET CURRENT CONTRACTOR SUCCESS",
  GET_CURRENT_CONTRACTOR_FAIL: "[CONTRACTORS] GET CURRENT CONTRACTOR FAIL",
} as const;

export const getContractorsRequest = () => ({
  type: ActionTypes.GET_CONTRACTORS_REQUEST,
})

export const getContractorsSuccess = (contractors: IContractor[], count: number) => ({
  type: ActionTypes.GET_CONTRACTORS_SUCCESS,
  payload: { contractors, count },
})

export const getContractorsFail = () => ({
  type: ActionTypes.GET_CONTRACTORS_FAIL,
})

export const updateContractorsPagination = (page: number) => ({
  type: ActionTypes.UPDATE_CONTRACTORS_PAGINATION,
  payload: { page },
})

export const updateContractorsFilter = (filter: Partial<IContractorsFilter>) => ({
  type: ActionTypes.UPDATE_CONTRACTORS_FILTER,
  payload: filter,
})

export const getContractorRequest = () => ({
  type: ActionTypes.GET_CURRENT_CONTRACTOR_REQUEST,
})

export const getContractorSuccess = (currentContractor: IContractor) => ({
  type: ActionTypes.GET_CURRENT_CONTRACTOR_SUCCESS,
  payload: currentContractor,
})

export const getContractorFail = () => ({
  type: ActionTypes.GET_CURRENT_CONTRACTOR_FAIL,
})
