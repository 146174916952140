import { useEffect } from "react";

import { Tabs } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getInvitations } from "store/invitations/invitations.thunks";
import { updateInvitationsFilter } from "store/invitations/invitations.actions";
import { getInvitationsSelector } from "store/invitations/invitations.selector";

const tabs = [
  {
    key: "new",
    label: "New",
  },
  {
    key: "rejected",
    label: "Rejected",
  },
];

const ContractInvitationsHeader = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(getInvitationsSelector);

  useEffect(() => {
    dispatch(getInvitations());
  }, [ filter.category ]);

  return (
    <Tabs
      items={ tabs }
      activeKey={ filter.category }
      onChange={ value => dispatch(updateInvitationsFilter({ category: value })) }
    />
  );
};

export default ContractInvitationsHeader;
