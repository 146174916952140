import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getContractorsFail,
  getContractorsRequest,
  getContractorsSuccess,
  getContractorRequest,
  getContractorSuccess,
  getContractorFail,
} from "./contractors.actions";
import api from "services/api.service";
import { IContractorsState } from "./contractors.types";
import { extraPortion } from "services/data.service";

export const getContractors = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  dispatch(getContractorsRequest());
  try {
    const { pagination, filter }: IContractorsState = getState().contractors;

    const response = await api.getContractors(filter, extraPortion, (pagination.page - 1) * extraPortion);

    dispatch(getContractorsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getContractorsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getContractor = (id: number) => async (dispatch: TAppDispatch) => {
  dispatch(getContractorRequest())
  try {
    const response = await api.getContractor(id);

    dispatch(getContractorSuccess(response.data));
  } catch (e: any) {
    dispatch(getContractorFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}
