export function arrayMove(arr: any, oldIndex: number, newIndex: number) {
  const copiedArr = [ ...arr ];
  const length = copiedArr.length;

  if (oldIndex !== newIndex && length > oldIndex && length > newIndex) {
    copiedArr.splice(newIndex, 0, copiedArr.splice(oldIndex, 1)[ 0 ]);
  }

  return copiedArr;
}
