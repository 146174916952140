import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import userReducer from "./user/user.reducer";
import casesReducer from "./cases/cases.reducer";
import downloadsReducer from "./downloads/downloads.reducer";
import listEquipmentReducer from "./listEquipment/listEquipment.reducer";
import contractsReducer from "./contracts/contracts.reducer";
import userManagementReducer from "./userManagement/userManagement.reducer";
import storageReducer from "./storage/storage.reducer";
import productsReducer from "./products/products.reducer";
import contractorsReducer from "./contractors/contractors.reducer";
import listInvitationReducer from "./invitations/invitations.reducer";

const store = configureStore({
  reducer: combineReducers({
    user: userReducer,
    cases: casesReducer,
    downloads: downloadsReducer,
    listEquipment: listEquipmentReducer,
    contracts: contractsReducer,
    userManagement: userManagementReducer,
    storage: storageReducer,
    products: productsReducer,
    contractors: contractorsReducer,
    invitations: listInvitationReducer,
  }),
})

export type TRootState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const useAppDispatch: () => TAppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

export default store;
