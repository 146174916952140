import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getExtraStorageFail,
  getExtraStorageRequest,
  getExtraStorageSuccess,
  getStorageFail,
  getStorageRequest,
  getStorageSuccess,
} from "./storage.actions";
import { IStorageState } from "./storage.types";
import api from "services/api.service";
import { extraLimit, extraPortion } from "services/data.service";

export const getStorage = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  try {
    dispatch(getStorageRequest())
    const { filter, pagination }: IStorageState = getState().storage;

    const response = await api.getStorage(filter, extraPortion, (pagination.page - 1) * extraLimit);

    dispatch(getStorageSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getStorageFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getExtraStorage = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  const { filter, pagination, isExtraStorageLoading, storage }: IStorageState = getState().storage;
  const offset = (pagination.page - 1) * extraLimit + storage.length;

  if (isExtraStorageLoading || storage.length >= extraLimit || offset === pagination.count) {
    return;
  }

  try {
    dispatch(getExtraStorageRequest());

    const response = await api.getStorage(filter, extraPortion, offset);

    dispatch(getExtraStorageSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getExtraStorageFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

