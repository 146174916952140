import { FC, useState } from "react";

import { Button, Form, Input, message } from "antd";
import { NavLink, useNavigate } from "react-router-dom";

import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";
import api from "services/api.service";

import "./ResetPasswordConfirmPage.scss";

const ResetPasswordConfirmPage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);
  const navigate = useNavigate();

  const [ isLoading, setIsLoading ] = useState(false);

  const handleSubmit = async (values: { password: string }) => {
    setIsLoading(true);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!token) {
      message.error("Follow the link from received mail!")
      setIsLoading(false);
      return;
    }

    try {
      await api.changePassword(values.password, token);

      setIsLoading(false);
      message.success("Your password was successfully updated!");
      navigate(RouteLinks.AUTH);
    } catch (e: any) {
      message.error(e.error || e.response.body.message || "Something went wrong!");
      setIsLoading(false);
    }
  }

  return (
    <div className={ `reset-password-confirm-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <Form
        className="reset-password-confirm-form-wrapper"
        layout="vertical"
        onFinish={ handleSubmit }
      >
        <h3>Please enter new password</h3>
        <Form.Item
          label="Password"
          name="password"
          rules={ [ { required: true, message: "Please input password!" } ] }
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          dependencies={ [ "password" ] }
          hasFeedback
          rules={ [
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ] }
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className="mg-btm-0">
          <Button className="big-btn" type="primary" htmlType="submit" disabled={ isLoading }>
            <Loading isLoading={ isLoading } height={ 23 } width={ 55 }>
              Confirm
            </Loading>
          </Button>
        </Form.Item>
      </Form>
      <p className="bottom-description">Already have an account? Log in <NavLink to={ RouteLinks.AUTH }>here</NavLink> or navigate the <NavLink to={ RouteLinks.MAIN }>main page</NavLink>.</p>
    </div>
  )
}

export default ResetPasswordConfirmPage;
