import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getDownloadsSelector } from "store/downloads/downloads.selector";
import { updateDownloadsPagination } from "store/downloads/downloads.actions";
import { getDownloads } from "store/downloads/downloads.thunks";

const DownloadsFooter: FC = () => {
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector(getDownloadsSelector);

  const handleUpdatePagination = (page: number, pageSize: number) => {
    dispatch(updateDownloadsPagination({
      page,
      rowsPerPage: pageSize,
    }))
    dispatch(getDownloads());
  }

  return (
    <div className="default-footer-wrapper">
      <Pagination
        total={ pagination.count }
        showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
        pageSize={ pagination.rowsPerPage }
        current={ pagination.page }
        onChange={ handleUpdatePagination }
      />
    </div>
  )
}

export default DownloadsFooter;
