import { IProduct, IProductCategory, IProductsFilter } from "./products.types";

export const ActionTypes = {
  GET_PRODUCTS_REQUEST: "[PRODUCTS] GET PRODUCTS REQUEST",
  GET_PRODUCTS_SUCCESS: "[PRODUCTS] GET PRODUCTS SUCCESS",
  GET_PRODUCTS_FAIL: "[PRODUCTS] GET PRODUCTS FAIL",
  UPDATE_PRODUCTS_PAGINATION: "[PRODUCTS] UPDATE PRODUCTS PAGINATION",
  GET_PRODUCTS_CATEGORIES_REQUEST: "[PRODUCTS] GET PRODUCTS CATEGORIES REQUEST",
  GET_PRODUCTS_CATEGORIES_SUCCESS: "[PRODUCTS] GET PRODUCTS CATEGORIES SUCCESS",
  GET_PRODUCTS_CATEGORIES_FAIL: "[PRODUCTS] GET PRODUCTS CATEGORIES FAIL",
  UPDATE_PRODUCTS_FILTER: "[PRODUCTS] UPDATE PRODUCTS FILTER",
  UPDATE_PRODUCT: "[PRODUCTS] UPDATE PRODUCT",
  GET_EXTRA_PRODUCTS_REQUEST: "[PRODUCTS] GET EXTRA PRODUCTS REQUEST",
  GET_EXTRA_PRODUCTS_SUCCESS: "[PRODUCTS] GET EXTRA PRODUCTS SUCCESS",
  GET_EXTRA_PRODUCTS_FAIL: "[PRODUCTS] GET EXTRA PRODUCTS FAIL",
} as const;

export const getProductsRequest = () => ({
  type: ActionTypes.GET_PRODUCTS_REQUEST,
})

export const getProductsSuccess = (products: IProduct[], count: number) => ({
  type: ActionTypes.GET_PRODUCTS_SUCCESS,
  payload: { products, count },
})

export const getProductsFail = () => ({
  type: ActionTypes.GET_PRODUCTS_FAIL,
})

export const updateProductsPagination = (page: number) => ({
  type: ActionTypes.UPDATE_PRODUCTS_PAGINATION,
  payload: { page },
})

export const getProductsCategoriesRequest = () => ({
  type: ActionTypes.GET_PRODUCTS_CATEGORIES_REQUEST,
})

export const getProductsCategoriesSuccess = (categories: IProductCategory[]) => ({
  type: ActionTypes.GET_PRODUCTS_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getProductsCategoriesFail = () => ({
  type: ActionTypes.GET_PRODUCTS_CATEGORIES_FAIL,
})

export const updateProductsFilter = (filter: Partial<IProductsFilter>) => ({
  type: ActionTypes.UPDATE_PRODUCTS_FILTER,
  payload: filter,
})

export const updateProduct = (product: IProduct) => ({
  type: ActionTypes.UPDATE_PRODUCT,
  payload: product,
})

export const getExtraProductsRequest = () => ({
  type: ActionTypes.GET_EXTRA_PRODUCTS_REQUEST,
})

export const getExtraProductsSuccess = (products: IProduct[], count: number) => ({
  type: ActionTypes.GET_EXTRA_PRODUCTS_SUCCESS,
  payload: { products, count },
})

export const getExtraProductsFail = () => ({
  type: ActionTypes.GET_EXTRA_PRODUCTS_FAIL,
})
