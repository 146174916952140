import { FC } from "react";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import { IDownload } from "store/downloads/downloads.types";
import { FileIcon } from "components/downloads";
import { getFileSizeTitle } from "services/title.service";
import downloadIcon from "data/icons/downloads-white.svg";
import { RouteLinks } from "services/router.service";
import exportIcon from "data/icons/export.svg";
import { downloadFromLink } from "services/data.service";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";

import "./DownloadCard.scss";

interface IProps {
  key: string | number;
  download: IDownload;
}

const DownloadCard: FC<IProps> = ({ key, download }) => {
  const navigate = useNavigate();
  const { isAdmin } = useAppSelector(getUserSelector);

  const handleOpenFile = () => {
    isAdmin && navigate(RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE + "/" + download.id)
  }

  return (
    <div key={ key } className="download-card-wrapper" onClick={ handleOpenFile }>
      <div className="download-card-header">
        <p className="download-card-date">{ download.date }</p>
        {/*<p className="download-card-deadline">{ download.deadline }</p>*/}
      </div>
      <div className="download-card-header">
        <p className="download-card-title">{ download.title }</p>
      </div>
      <div className="download-card-icon">
        <FileIcon size="large" fileType={ "link" } fileName={ download.title } />
        <p className="download-card-file-name">{ download.description }</p>
      </div>
      <div className="download-card-footer">
        <div className="download-card-footer-info">
          <p className="download-card-footer-title">Filesize:</p>
          <p className="download-card-footer-value">{ getFileSizeTitle(download.source_size) }</p>
        </div>
        <Button
          type="primary"
          className="download-button"
          onClick={ (e) => {
            e.stopPropagation();
            downloadFromLink(download.link);
          } }
        >
          <img src={ download.link === "" ? downloadIcon : exportIcon } alt="" />
        </Button>
      </div>
    </div>
  )
}

export default DownloadCard;
