import { FC } from "react";

import { message, Tooltip } from "antd";
import { Link } from "react-router-dom";

import { useAppSelector } from "store";
import { getCasesSelector } from "store/cases/cases.selector";
import { getUserSelector } from "store/user/user.selector";
import copyBlueIcon from "data/icons/copy-blue.svg";

import "./ContactInfoCard.scss";

const ContactInfoCard: FC = () => {
  const { currentCase } = useAppSelector(getCasesSelector);
  const { isAdmin } = useAppSelector(getUserSelector);

  const onCopyLink = () => {
    try {
      if (currentCase) {
        navigator.clipboard.writeText(currentCase.ext_issue_tracker?.link || "");
        message.success("Link copied!")
      }
    } catch (e) {
      message.error("Something went wrong!")
    }
  };

  return currentCase ? (
    <div className="contact-info-card-wrapper">
      <p className="contact-info-title">Contact Information</p>
      <div className="info-wrapper">
        <div className="info-item">
          <p className="title">Name:</p>
          <p className="value">{ currentCase.contact_name }</p>
        </div>

        <div className="info-item">
          <p className="title">Email:</p>
          <p className="value">{ currentCase.contact_email }</p>
        </div>

        <div className="info-item">
          <p className="title">Phone:</p>
          <p className="value">{ currentCase.contact_phone_number }</p>
        </div>

        {currentCase.email_cc && (
          <div className="info-item">
            <p className="title">CC:</p>

            <p className="value">
              { currentCase.email_cc?.map(email => (
                <div key={ email }>{ email }</div>
              )) }
            </p>
          </div>
        )}
      </div>

      { isAdmin && currentCase.ext_issue_tracker?.link && (
        <div className="task-link-wrapper">
          <p className="task-link-title">
            External Issue Tracker Link
          </p>

          <div className="task-link-item">
            <img
              alt=""
              className="link-row-img"
              src={ currentCase.ext_issue_tracker?.source_logo }
            />

            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              href={ currentCase.ext_issue_tracker?.link || "" }
            >
              { currentCase.ext_issue_tracker?.link || "" }
            </a>

            <Tooltip title="Click to copy URL">
              <img
                alt=""
                onClick={ onCopyLink }
                src={ copyBlueIcon }
                style={ { marginLeft: "auto" } }
              />
            </Tooltip>
          </div>
        </div>
      ) }
    </div>
  ) : null;
}

export default ContactInfoCard;
