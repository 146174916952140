import { FC, useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { useAppDispatch } from "store";
import { getDownloads } from "store/downloads/downloads.thunks";

const DownloadsPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getDownloads());
  }, [ location.pathname ])

  return (
    <div className="default-page-wrapper">
      <Outlet />
    </div>
  )
}

export default DownloadsPage;
