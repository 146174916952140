import { FC, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, message } from "antd";
import dayjs from "dayjs";

import {
  ContractEquipmentsFilter,
  ContractEquipmentsTable,
  ContractInfoForm,
  ContractEquipmentsPagination,
} from "components/contracts";
import { RouteLinks } from "services/router.service";
import backIcon from "data/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { IContractEquipment } from "store/contracts/contracts.types";
import api from "services/api.service";

import "./CurrentContractPage.scss";

const CurrentContractPage: FC = () => {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const dispatch = useAppDispatch();
  const { isCurrentContractLoading } = useAppSelector(getContractsSelector);

  const [ contractEdit, setContractEdit ] = useState<boolean>(false);
  const [ equipmentsForm ] = Form.useForm();
  
  const onSubmitEquipments = async (currentContractId: number): Promise<IEquipmentSource[]> => {
    const formEquipments = equipmentsForm.getFieldsValue(true);

    let contractEquipments = [];
    try {
      contractEquipments = await api.setContractEquipments(
        currentContractId,
        [
          ...formEquipments.equipment.map((contractEquipment: IContractEquipment) => ({
            id: contractEquipment.id,
            serial_number: contractEquipment.serial_number,
            product: contractEquipment.product?.id || contractEquipment.product,
            date_begin: dayjs(contractEquipment.date_begin).format("YYYY-MM-DD"),
            date_end: dayjs(contractEquipment.date_end).format("YYYY-MM-DD"),
            // @ts-ignore
            sla_ci: contractEquipment.sla_ci?.value || contractEquipment.sla_ci,
            quantity: 1,
          })),
        ],
      );
    } catch(e) {
      message.error("Something goes wrong.");
    }

    return contractEquipments;
  };

  useEffect(() => {
    if (contractId) {
      dispatch(getContract(+contractId));
      dispatch(getContractEquipments(+contractId));
    }
  }, [])

  return (
    <div className="default-page-wrapper current-contract-page-wrapper">
      <Button
        className="back-button"
        type="link"
        onClick={ () => navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN) }
      >
        <img src={ backIcon } alt="" />
        Back
      </Button>
      {/*<ContractInfo />*/}
      <ContractInfoForm
        contractEdit={ contractEdit }
        setContractEdit={ setContractEdit }
        onSubmitEquipments={ onSubmitEquipments }
      />
      { !isCurrentContractLoading && <ContractEquipmentsFilter /> }
      <ContractEquipmentsTable
        contractEdit={ contractEdit }
        equipmentsForm={ equipmentsForm }
      />
      <ContractEquipmentsPagination />
    </div>
  )
}

export default CurrentContractPage;
