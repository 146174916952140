import { FC, useState } from "react";

import { Layout, Modal } from "antd";

import { useAppSelector } from "store";
import { CaseFormSupport } from "components/common";
import ContactUsModal from "components/common/ContactUsModal";
import GetInTouchModal from "components/common/GetInTouchModal";
import { getUserSelector } from "store/user/user.selector";
import contractSuccess from "data/images/support.svg";
import hydraLogoGray from "data/icons/hydra-logo-gray.svg";

import "./WelcomePage.scss";

const WelcomePage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);

  const [ isContactUsOpen, setIsContactUsOpen ] = useState(false);
  const [ isGetInTouchOpen, setIsGetInTouchOpen ] = useState(false);

  return (
    <Layout className={ `welcome-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <div className="group">
        <div>
          <h1>Welcome to Support Portal</h1>

          <div className="form-wrapper">
            <CaseFormSupport/>

            {/*<div className="info">*/}
            {/*  Нажимая кнопку «Open Case», вы подтверждаете, что ознакомлены<br/>*/}
            {/*   и принимаете условия*/}
            {/*  {" "}*/}
            {/*  <span className="info-link">*/}
            {/*    Пользовательского соглашения*/}
            {/*  </span>*/}
            {/*</div>*/}

            <div
              className="info"
              onClick={ () => setIsContactUsOpen(true) }
            >
              Need help with something?
              {" "}
              <span className="info-link">
                Contact us
              </span>
            </div>
          </div>
        </div>

        <div className="group-right">
          <img
            alt=""
            src={ contractSuccess }
          />

          <div className="group-container">
            <div className="group-footer">
              <span>Powered by</span>

              <img
                alt=""
                src={ hydraLogoGray }
              />
            </div>

            <div>
              Got any question about platform?
              {" "}
              <span className="info-link" onClick={ () =>  setIsGetInTouchOpen(true) }>Get in touch</span> <br/>
              with our friendly team and we’ll help you.
            </div>
          </div>
        </div>
      </div>

      <ContactUsModal
        isContactUsOpen={ isContactUsOpen }
        setIsContactUsOpen={ setIsContactUsOpen }
      />

      <GetInTouchModal
        isGetInTouchOpen={ isGetInTouchOpen }
        setIsGetInTouchOpen={ setIsGetInTouchOpen }
      />
    </Layout>
  )
}

export default WelcomePage;
